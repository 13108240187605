import { useContext, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { scroller, Element } from 'react-scroll';

import Progress from '../elements/progress/Progress';

import { FeatureFlagContext } from './../../providers/FeatureFlagProvider';
import FormError from './FormError/FormError';
import { FormStep3MiniView } from './formStep3/FormStep3MiniView';
import { FormStep5MiniView } from './formStep5/FormStep5MiniView';

const getProgressTitle = (step: 1 | 2 | 3 | 4) => {
    if (step === 3) {
        return 'Вероятность окончательного одобрения';
    }

    return 'Вероятность одобрения';
};

export const FormQuestView = ({
    isError,
    isLoading,
    onSubmit,
    formRef,
    currentPoints,
    nextPoints,
    title,
    subTitle,
    step,
    totalPoints,
    children,
}: any) => {
    const flags = useContext(FeatureFlagContext);

    const match = useMatch('/anketa');

    useEffect(() => {
        function scrollToForm() {
            if (match) {
                scroller.scrollTo('form-quest', {
                    duration: 750,
                    smooth: true,
                    spy: true,
                    isDynamic: true,
                    ignoreCancelEvents: true,
                });
            }
        }

        window.addEventListener('load', () => scrollToForm());

        return () => window.removeEventListener('load', () => scrollToForm());
    }, [match]);
    return (
        <form id="form-quest" className="form-quest" onSubmit={onSubmit} ref={formRef}>
            {isError ? (
                <FormError />
            ) : isLoading ? (
                step === 2 ? (
                    <FormStep3MiniView />
                ) : (
                    <FormStep5MiniView />
                )
            ) : (
                <div className="form-quest__content">
                    {flags?.disableCustomSteps ? (
                        <>
                            <Element name="form-quest"></Element>
                            <span className="discount_label">
                                ПРОМОКОД «SKIDKA 15%» АКТИВИРОВАН
                            </span>

                            <h3 className="form-quest__title">{title}</h3>
                            <span className="form-quest__text">{subTitle}</span>
                            <Progress
                                currentPoints={currentPoints}
                                nextPoints={nextPoints}
                                totalPoints={totalPoints.current}
                                title={getProgressTitle(step)}
                            />
                        </>
                    ) : step === 1 || step === 2 ? (
                        <>
                            <h3 className="form-quest__title">{title}</h3>
                            <span className="form-quest__text">{subTitle}</span>

                            <Progress
                                currentPoints={currentPoints}
                                nextPoints={nextPoints}
                                totalPoints={totalPoints.current}
                                title={getProgressTitle(step)}
                            />
                        </>
                    ) : null}
                    {children}
                </div>
            )}
        </form>
    );
};
