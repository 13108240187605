import { FC } from 'react';

import BannerThanks from './../layout/banner/bannerThanks/BannerThanks';
import Wrapper from './../layout/wrapper/Wrapper';

const Thanks: FC = () => {
    return (
        <Wrapper>
            <BannerThanks />
        </Wrapper>
    );
};

export default Thanks;
