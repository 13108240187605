import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';

export interface FeatureFlags {
    disableCustomSteps: boolean;
}

export const FeatureFlagContext = createContext<FeatureFlags | null>(null);

export const FeatureFlagProvider: FC<PropsWithChildren> = ({ children }) => {
    const [flags, setFlags] = useState(null);

    useEffect(() => {
        fetch('/flags.json')
            .then((response) => response.json())
            .then(setFlags);
    }, []);

    return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>;
};
