import { useContext, useEffect, useRef, useState } from 'react';
import { RenderSuggestion } from 'react-autosuggest';
import HighlightWords from 'react-highlight-words';

import { useDadataAddress } from '../../../../hooks/dadataApiHooks';
import { SuggestInput } from '../../suggestInput/SuggestInput';

import {
    setPlaceResidence,
    setPlaceResidenceDependentFields,
    setOrganizationDependentFields,
    setWorkAddress,
} from './../../../../actions/formQuest';
import { FormQuestContext } from './../../../../reducer/reducer';
import useStep3 from './../../../../services/formQuestServise/Step3Servise';

/** параметры дадаты */
const locations = [{ country_iso_code: 'RU' }];
const params = {
    count: 5,
    locations,
    from_bound: { value: 'region' },
    to_bound: { value: 'flat' },
};

/** маппер для получения suggestionValue */
const getSuggestionValue = (suggestion: any) => suggestion.value;

/** рендер подсказки в списке */
const renderSuggestion: RenderSuggestion<any> = (suggestion, params) => {
    return (
        <div>
            <HighlightWords
                searchWords={[
                    new RegExp(
                        params.query.replace(/([!@#$%^&*()+=\\[\]\\';,./{}|":<>?~_-])/g, '\\$1'),
                        'g',
                    ),
                ]}
                textToHighlight={suggestion.value}
            />
        </div>
    );
};

export const PlaceResidenceSuggest = () => {
    const [focused, setFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const inputRef = useRef<HTMLInputElement>(null);
    const currentAddress = useRef<any>(null);

    const { validateWorkAddress } = useStep3();

    const { data, trigger } = useDadataAddress(params);

    const { state, dispatch } = useContext(FormQuestContext);
    const {
        placeResidence,
        organization,
        employment,
        workOpf,
        okvedMain,
        workInn,
        workHead,
        registrationStreet,
        registrationHomeKladr,
        workAddress,
    } = state;

    const { maskPlaceResidence, validationPlaceResidence } = useStep3();

    const handleChange = (e: any, { newValue }: any) => {
        setPlaceResidence(dispatch, placeResidence, maskPlaceResidence(newValue), false, false);
        setPlaceResidenceDependentFields(dispatch, {
            registrationСode: '',
            registrationRegion: '',
            registrationCity: '',
            registrationStreet: '',
            registrationHome: '',
            registrationFlat: '',
            registrationHomeKladr: '',
        });
    };

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        trigger(value);
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (e: any, data: any) => {
        const placeResidenceValue = data.suggestion.value;
        currentAddress.current = data;
        const isValid = validationPlaceResidence(
            placeResidence.errorMessages,
            {
                value: placeResidenceValue,
                city: data.suggestion.data.city || data.suggestion.data.settlement,
                street: data.suggestion.data.street,
                house: data.suggestion.data.house,
            },
            true,
        );

        setPlaceResidence(
            dispatch,
            placeResidence,
            isValid !== true
                ? `${maskPlaceResidence(placeResidenceValue)} `
                : maskPlaceResidence(placeResidenceValue),
            isValid,
            true,
        );

        if (isValid === true) {
            setPlaceResidenceDependentFields(dispatch, {
                registrationСode:
                    data.suggestion.data.city_kladr_id ||
                    data.suggestion.data.area_kladr_id ||
                    data.suggestion.data.region_kladr_id ||
                    '',
                registrationRegion: data.suggestion.data.region_with_type,
                registrationCity: data.suggestion.data.city || data.suggestion.data.settlement,
                registrationStreet: data.suggestion.data.street || '',
                registrationHome: data.suggestion.data.house || '',
                registrationFlat: data.suggestion.data.flat || '',
                registrationHomeKladr: data.suggestion.data.house_kladr_id || '',
            });
        }
    };

    useEffect(() => {
        const addressData = currentAddress.current?.suggestion;
        const isValid =
            validateWorkAddress(
                workAddress.errorMessages,
                {
                    value: addressData?.value || '',
                    city: addressData?.data?.city || addressData?.data?.settlement,
                    street: addressData?.data?.street,
                    house: addressData?.data?.house,
                },
                true,
            ) === true;

        if (isValid === true) {
            if (
                (employment.value === '58' && workOpf.value === 'ИП') ||
                employment.value === '238'
            ) {
                setWorkAddress(dispatch, workAddress, addressData?.value, true, true);
                setOrganizationDependentFields(dispatch, {
                    workInn: workInn.value,
                    workHead: workHead.value,
                    workCity: addressData?.data?.city || addressData?.data?.settlement || null,
                    workStreet: registrationStreet?.value || 'Нет',
                    workHouse: addressData?.data?.house || '',
                    workOpf: workOpf.value,
                    workRegionKladr: addressData?.data?.region_kladr_id || '',
                    workRegion: addressData?.data.region_with_type || '',
                    workAddress: addressData?.data?.region_with_type || '',
                    okvedMain: okvedMain.value || '',
                    workCityKladr: addressData?.data?.city_kladr_id || '',
                    workStreetKladr: addressData?.data?.region_kladr_id || '',
                    workHouseKladr: registrationHomeKladr?.value,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeResidence, organization]);

    useEffect(() => {
        if (data) {
            setSuggestions(data.suggestions);
        }
    }, [data]);

    const inputProps = {
        placeholder: 'Адрес прописки',
        value: placeResidence.value,
        onChange: handleChange,
        onFocus: () => {
            setFocused(true);

            if (!focused && inputRef.current) {
                const r = inputRef.current;

                setTimeout(() => {
                    r.setSelectionRange(placeResidence.value.length, placeResidence.value.length);
                }, 10);
            }
        },
        onBlur: () => {
            setPlaceResidence(
                dispatch,
                placeResidence,
                maskPlaceResidence(placeResidence.value),
                placeResidence.isDadata
                    ? placeResidence.isValid
                    : placeResidence.value === ''
                    ? placeResidence.isValid
                    : placeResidence.errorMessages.manual,
                false,
            );

            setFocused(false);
        },
        ref: inputRef,
    };

    return (
        <SuggestInput
            label="Адрес прописки"
            inputProps={inputProps}
            isValid={placeResidence.isValid}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
        />
    );
};
