import { FC, useContext, useEffect, useRef, useState } from 'react';
import { RenderSuggestion } from 'react-autosuggest';
import HighlightWords from 'react-highlight-words';

import {
    setContactPersonSurname,
    setFio,
    setContactPersonFirstname,
    setContactPersonPatronymic,
} from '../../../../actions/formQuest';
import { useDadataNames } from '../../../../hooks/dadataApiHooks';
import { FormQuestContext } from '../../../../reducer/reducer';
import useStep1 from '../../../../services/formQuestServise/Step1Servise';
import { getBadgeContent } from '../../../../utils';
import { Badge } from '../../badge/Badge';
import { SuggestInput } from '../../suggestInput/SuggestInput';

interface Props {
    onSetValidValue: () => void;
}

/** параметры дадаты */
const locations = [{ country_iso_code: 'RU' }];
const params = { count: 10, locations };

/** маппер для получения suggestionValue */
const getSuggestionValue = (suggestion: any) => suggestion.value;

/** рендер подсказки в списке */
const renderSuggestion: RenderSuggestion<any> = (suggestion, params) => {
    return (
        <div>
            <HighlightWords
                searchWords={[
                    new RegExp(
                        params.query.replace(/([!@#$%^&*()+=\\[\]\\';,./{}|":<>?~_-])/g, '\\$1'),
                        'g',
                    ),
                ]}
                textToHighlight={suggestion.value}
            />
        </div>
    );
};

export const FioSuggest: FC<Props> = ({ onSetValidValue }) => {
    const [focused, setFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const { data, trigger } = useDadataNames(params);

    const { state, dispatch } = useContext(FormQuestContext);
    const { fio, contactPersonFirstname, contactPersonSurname, contactPersonPatronymic } = state;

    const { maskFio, validationFio } = useStep1();

    const handleChange = (e: any, { newValue }: any) => {
        setFio(dispatch, fio, maskFio(newValue), false);
    };

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        trigger(value);
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (e: any, data: any) => {
        const fioValue = data.suggestion.value;
        const isValid = validationFio(fio.errorMessages, fioValue);

        setFio(
            dispatch,
            fio,
            isValid !== true ? `${maskFio(fioValue)} ` : maskFio(fioValue),
            isValid,
        );

        if (data.suggestion.data.surname != null) {
            setContactPersonSurname(
                dispatch,
                contactPersonSurname,
                data.suggestion.data.surname,
                true,
            );
        } else {
            setContactPersonSurname(dispatch, contactPersonSurname, '', false);
        }

        if (data.suggestion.data.name != null) {
            setContactPersonFirstname(
                dispatch,
                contactPersonFirstname,
                data.suggestion.data.name,
                true,
            );
        } else {
            setContactPersonFirstname(dispatch, contactPersonFirstname, '', false);
        }

        if (data.suggestion.data.patronymic != null) {
            setContactPersonPatronymic(
                dispatch,
                contactPersonPatronymic,
                data.suggestion.data.patronymic,
                true,
            );
        } else {
            setContactPersonPatronymic(dispatch, setContactPersonPatronymic, '', false);
        }

        if (isValid === true) {
            setTimeout(() => {
                onSetValidValue();
            });
        }
    };

    useEffect(() => {
        if (data) {
            setSuggestions(data.suggestions);
        }
    }, [data]);

    const inputProps = {
        placeholder: 'Иванов Иван Иванович',
        value: fio.value,
        onChange: handleChange,
        onKeyDown: (e: any) => {
            if (e.key === 'Enter') {
                const isValid = validationFio(fio.errorMessages, fio.value);
                setFio(dispatch, fio, maskFio(fio.value), isValid);

                if (isValid === true) {
                    onSetValidValue();
                }
            }
        },
        onFocus: () => {
            setFocused(true);

            if (!focused && inputRef.current) {
                const r = inputRef.current;
                setTimeout(() => {
                    r.setSelectionRange(fio.value.length, fio.value.length);
                }, 10);
            }
        },
        onBlur: () => {
            setFocused(false);

            const isValid = validationFio(fio.errorMessages, fio.value);
            setFio(dispatch, fio, maskFio(fio.value), isValid);
        },
        ref: inputRef,
    };

    const badgeContent = getBadgeContent(fio.points);

    const inputBadge = fio.isValid ? (
        fio.isValid === true ? null : (
            <Badge badgeContent={badgeContent} />
        )
    ) : focused ? (
        <Badge badgeContent={badgeContent} />
    ) : null;

    return (
        <SuggestInput
            label="ФИО"
            inputBadge={inputBadge}
            inputProps={inputProps}
            isValid={fio.isValid}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
        />
    );
};
