import { FC, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import BannerLoading from './../layout/banner/bannerLoading/BannerLoading';
import Footer from './../layout/footer/Footer';
import Header from './../layout/header/Header';

const Prepare: FC = () => {
    const navigate = useNavigate();
    const { slug } = useParams();

    useEffect(() => {
        if (!slug) {
            navigate('/', { replace: true });
            return;
        }

        const interval = Date.now() - Number.parseInt(slug, 10);

        if (interval > 100000) {
            navigate('/', { replace: true });
        }
    }, [slug, navigate]);

    return (
        <>
            <Header />
            <BannerLoading />
            <Footer />
        </>
    );
};

export default Prepare;
