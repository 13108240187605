import { FC } from 'react';

import Pay from '../../pay/Pay';

import './style.css';

import girlWithMoney from './../../../../assets/imgs/banner/girl-with-money.png';
import ButtonScroll from './../../../elements/button/buttonScroll/ButtonScroll';
import BannerInfo from './../bannerInfo/BannerInfo';

const BannerInner: FC = () => {
    return (
        <div className="banner-inner container">
            <div className="banner-inner__content row jc-sb ai-s">
                <div className="banner-inner__info banner-inner-info">
                    <h1 className="banner-inner-info__title">
                        <span>
                            Реальный кредит с любой кредитной историей за 30 минут без залога
                        </span>
                    </h1>
                    <Pay />
                    <ButtonScroll />
                </div>
                <div className="banner-inner__img">
                    <img src={girlWithMoney} alt="Клиент" />
                </div>
                <BannerInfo />
            </div>
        </div>
    );
};

export default BannerInner;
