import ButtonForm from './../../elements/button/buttonForm/ButtonForm';

import './style.css';

const FormError = () => {
    return (
        <div className="form-quest__content content-error">
            <h3 className="form-quest__title title-error form-quest__title-mb-2">
                ПРОИЗОШЛА ОШИБКА ПРИ ОТПРАВКЕ ДАННЫХ!
            </h3>
            <span className="form-quest__text text-center">
                Уже исправляем.. Приносим извинения за неудобства. В качестве компенсации дарим Вам
                промокод, с которым кредит станет еще выгоднее. Перейдите по ссылке ниже для
                активации персонального промокода «АИ-158».
            </span>
            <div className="form-step__btns row btns-center">
                <ButtonForm
                    text="Перейти к анкете"
                    setFunction={(e: KeyboardEvent) => {
                        e.preventDefault();
                        window.location.href = 'https://reshbank.ru/promo';
                    }}
                />
            </div>
        </div>
    );
};

export default FormError;
