import ClockLoader from 'react-spinners/ClockLoader';

import { override } from './styles';

export const FormStep5MiniView = () => {
    return (
        <div className="form-quest__content">
            <h3 className="form-quest__title">ЗАГРУЗКА...</h3>
            <ClockLoader
                color="#21ba72"
                loading={true}
                cssOverride={override}
                size={100}
                aria-label="Загрузка"
                data-testid="loader"
            />
        </div>
    );
};
