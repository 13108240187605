import { FC, useState, useRef, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import { FormQuestContext } from '../../reducer/reducer';

import {
    setFio,
    setSum,
    setDateBirth,
    setPhone,
    setEmail,
    setCity,
    setCityCreditKladr,
    setRegionCredit,
    setIncome,
    setEmployment,
    setMethodConfirmingIncome,
    setCreditHistory,
    setEducation,
    setOwn,
    setStartWorkLastPlaceMonth,
    setStartWorkLastPlaceYear,
    setOrganization,
    setOrganizationDependentFields,
    setWorkPhone,
    setTotalWorkExperience,
    setFamilyStatus,
    setAmountChildren,
    setLoansPerMonth,
    setPlaceResidence,
    setPlaceResidenceDependentFields,
    setFullStep,
    setPassportNumberSeries,
    setDateOfIssue,
    setUnitСode,
    setIssuedBy,
    setPlaceOfBirth,
    setContactPhone,
    setContactPersonFio,
    setWorkAddress,
    setWorkAddressDependentFields,
} from './../../actions/formQuest';
import { FeatureFlagContext } from './../../providers/FeatureFlagProvider';
import useStep1 from './../../services/formQuestServise/Step1Servise';
import useStep3 from './../../services/formQuestServise/Step3Servise';
import useStep4 from './../../services/formQuestServise/Step4Servise';
import useSend from './../../services/SendService';
import {
    formQuestInitStore,
    step1Fields,
    step2Fields,
    step3Fields,
} from './../../store/formQuestInitStore';
import { scrollIntoView } from './../../utils';
import { FormQuestView } from './FormQuestView';
import FormStep1 from './formStep1/FormStep1';
import FormStep2 from './formStep2/FormStep2';
import FormStep3 from './formStep3/FormStep3';
import FormStep4 from './formStep4/FormStep4';
import './style.css';
import { FormStep5View } from './formStep5/FormStep5View';

const getTotalPoints = () => {
    return [...Object.values(formQuestInitStore)].reduce((prev, cur) => prev + cur.points, 0);
};

const getTitle = (step: 1 | 2 | 3 | 4, sum?: string) => {
    if (step === 3 && sum) {
        return `КРЕДИТ НА ${sum} РУБЛЕЙ ПРЕДВАРИТЕЛЬНО ОДОБРЕН!`;
    }

    if (step === 4) {
        return 'ВАМ ДОСТУПНА ЛЬГОТНАЯ СТАВКА 6% ГОДОВЫХ!';
    }

    return 'ПОЛУЧИТЕ ОДОБРЕНИЕ КРЕДИТА ЗА 30 МИНУТ';
};

const getSubTitle = (step: 1 | 2 | 3 | 4) => {
    if (step === 4) {
        return 'Остался последний шаг! Заполните его и получите кредит за 30 минут по паспорту';
    }

    return 'Заполните анкету полностью, чтобы получить максимальное количество предложений!';
};

const FormQuest: FC = () => {
    const formRef = useRef<any>(null);
    const flags = useContext(FeatureFlagContext);
    const { state, dispatch } = useContext(FormQuestContext);
    const {
        fio,
        sum,
        dateBirth,
        phone,
        email,
        city,
        cityCreditKladr,
        regionCredit,
        checkbox1,
        checkbox2,
        income,
        employment,
        methodConfirmingIncome,
        creditHistory,
        education,
        own,
        startWorkLastPlaceMonth,
        startWorkLastPlaceYear,
        organization,
        workAddress,
        workInn,
        workHead,
        workCity,
        workStreet,
        workHouse,
        workOpf,
        workRegion,
        workRegionKladr,
        okvedMain,
        workCityKladr,
        workStreetKladr,
        workHouseKladr,
        workPhone,
        totalWorkExperience,
        familyStatus,
        amountChildren,
        loansPerMonth,
        placeResidence,
        registrationСode,
        registrationRegion,
        registrationCity,
        registrationStreet,
        registrationHome,
        registrationFlat,
        registrationHomeKladr,
        passportNumberSeries,
        dateOfIssue,
        unitСode,
        issuedBy,
        placeOfBirth,
        contactPhone,
        contactPerson,
        contactPersonFio,
        contactPersonFirstname,
        contactPersonSurname,
        contactPersonPatronymic,
    } = state;

    const totalPoints = useRef(getTotalPoints());
    const [currentPoints, setCurrentPoints] = useState(0);
    const [nextPoints, setNextPoints] = useState(0);

    const [error400, setError400] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [yandexMetrikaClientId, setYandexMetrikaClientId] = useState('');
    // const secunds = useRef<any>(null);

    const copy = (aObject: any) => {
        const bObject: any = Array.isArray(aObject) ? [] : {};

        let value;
        for (const key in aObject) {
            value = aObject[key];
            bObject[key] = typeof value === 'object' ? copy(value) : value;
        }

        return bObject;
    };

    const [searchParams] = useSearchParams();
    const { slug } = useParams();
    const { createDeal, updateDeal, postEncrypt } = useSend();

    const startLocalStorage = () => {
        const step = localStorage.getItem('step');

        if (step == null) {
            return;
        }

        const data = {
            ...copy(step1Fields),
            ...copy(step2Fields),
            ...copy(step3Fields),
        };

        if (+step >= 3) {
            data['organization']['value'] = localStorage.getItem('organization') || '';
            data['organization']['isValid'] =
                localStorage.getItem('organization') && localStorage.getItem('organization') !== '';
            data['workAddress']['value'] = localStorage.getItem('workAddress') || '';
            data['workAddress']['isValid'] = true;
            data['workInn']['value'] = localStorage.getItem('workInn') || '';
            data['workInn']['isValid'] = true;
            data['workHead']['value'] = localStorage.getItem('workHead') || '';
            data['workHead']['isValid'] = true;
            data['workCity']['value'] = localStorage.getItem('workCity') || '';
            data['workCity']['isValid'] = true;
            data['workStreet']['value'] = localStorage.getItem('workStreet') || '';
            data['workStreet']['isValid'] = true;
            data['workHouse']['value'] = localStorage.getItem('workHouse') || '';
            data['workHouse']['isValid'] = true;
            data['workOpf']['value'] = localStorage.getItem('workOpf') || '';
            data['workOpf']['isValid'] = true;
            data['workRegionKladr']['value'] = localStorage.getItem('workRegionKladr') || '';
            data['workRegionKladr']['isValid'] = true;
            data['workRegion']['value'] = localStorage.getItem('workRegion') || '';
            data['workRegion']['isValid'] = true;
            data['okvedMain']['value'] = localStorage.getItem('okvedMain') || '';
            data['okvedMain']['isValid'] = true;
            data['workCityKladr']['value'] = localStorage.getItem('workCityKladr') || '';
            data['workCityKladr']['isValid'] = true;
            data['workStreetKladr']['value'] = localStorage.getItem('workStreetKladr') || '';
            data['workStreetKladr']['isValid'] = true;
            data['workHouseKladr']['value'] = localStorage.getItem('workHouseKladr') || '';
            data['workHouseKladr']['isValid'] = true;
            data['workPhone']['value'] = localStorage.getItem('workPhone') || '';
            data['workPhone']['isValid'] =
                localStorage.getItem('workPhone') && localStorage.getItem('workPhone') !== '';
            data['totalWorkExperience']['value'] =
                localStorage.getItem('totalWorkExperience') || '';
            data['totalWorkExperience']['isValid'] = true;
            data['familyStatus']['value'] = localStorage.getItem('familyStatus') || '';
            data['familyStatus']['isValid'] = true;
            data['amountChildren']['value'] = localStorage.getItem('amountChildren') || '';
            data['amountChildren']['isValid'] = true;
            data['loansPerMonth']['value'] = localStorage.getItem('loansPerMonth') || '';
            data['loansPerMonth']['isValid'] = true;
            data['placeResidence']['value'] = localStorage.getItem('placeResidence') || '';
            data['placeResidence']['isValid'] = true;
            data['registrationСode']['value'] = localStorage.getItem('registrationСode') || '';
            data['registrationСode']['isValid'] = true;
            data['registrationRegion']['value'] = localStorage.getItem('registrationRegion') || '';
            data['registrationRegion']['isValid'] = true;
            data['registrationCity']['value'] = localStorage.getItem('registrationCity') || '';
            data['registrationCity']['isValid'] = true;
            data['registrationStreet']['value'] = localStorage.getItem('registrationStreet') || '';
            data['registrationStreet']['isValid'] = true;
            data['registrationHome']['value'] = localStorage.getItem('registrationHome') || '';
            data['registrationHome']['isValid'] = true;
            data['registrationFlat']['value'] = localStorage.getItem('registrationFlat') || '';
            data['registrationFlat']['isValid'] = true;
            data['registrationHomeKladr']['value'] =
                localStorage.getItem('registrationHomeKladr') || '';
            data['registrationHomeKladr']['isValid'] = true;
        }

        if (+step >= 2) {
            data['income']['value'] = localStorage.getItem('income') || '';
            data['income']['isValid'] = true;
            data['employment']['value'] = localStorage.getItem('employment') || '';
            data['employment']['isValid'] = true;
            data['methodConfirmingIncome']['value'] =
                localStorage.getItem('methodConfirmingIncome') || '';
            data['methodConfirmingIncome']['isValid'] = true;
            data['creditHistory']['value'] = localStorage.getItem('creditHistory') || '';
            data['creditHistory']['isValid'] = true;
            data['education']['value'] = localStorage.getItem('education') || '';
            data['education']['isValid'] = true;
            data['own']['value'] = localStorage.getItem('own') || '';
            data['own']['isValid'] = true;
            data['startWorkLastPlaceMonth']['value'] =
                localStorage.getItem('startWorkLastPlaceMonth') || '';
            data['startWorkLastPlaceMonth']['isValid'] = true;
            data['startWorkLastPlaceYear']['value'] =
                localStorage.getItem('startWorkLastPlaceYear') || '';
            data['startWorkLastPlaceYear']['isValid'] = true;
        }

        if (+step >= 1) {
            data['fio']['value'] = localStorage.getItem('fio') || '';
            data['fio']['isValid'] = true;
            data['sum']['value'] = localStorage.getItem('sum') || '';
            data['sum']['isValid'] = true;
            data['dateBirth']['value'] = localStorage.getItem('dateBirth') || '';
            data['dateBirth']['isValid'] = true;
            data['phone']['value'] = localStorage.getItem('phone') || '';
            data['phone']['isValid'] = true;
            data['email']['value'] = localStorage.getItem('email') || '';
            data['email']['isValid'] = true;
            data['city']['value'] = localStorage.getItem('city') || '';
            data['city']['isValid'] = true;
            data['cityCreditKladr']['value'] = localStorage.getItem('cityCreditKladr') || '';
            data['cityCreditKladr']['isValid'] = true;
            data['regionCredit']['value'] = localStorage.getItem('regionCredit') || '';
            data['regionCredit']['isValid'] = true;
            data['checkbox2']['isValid'] =
                localStorage.getItem('mailCheckbox') &&
                localStorage.getItem('mailCheckbox') === 'true'
                    ? true
                    : false;
            data['checkbox2']['value'] = data['checkbox2']['isValid'] ? 'true' : '';
        }

        if (+step === 1) {
            setStep(2);
        } else if (+step === 2) {
            setStep(3);
        } else if (+step === 3) {
            setStep(4);
        }

        setFullStep(dispatch, data);
    };

    const linkData = (d: any) => {
        const data = {
            ...copy(step1Fields),
            ...copy(step2Fields),
            ...copy(step3Fields),
        };

        const deal = d.data.deal;
        const contact = d.data.contact;

        localStorage.setItem('deaiId', deal.id);

        let step: 1 | 2 | 3 | 4 = 1;

        if (contact.fio) {
            step = 2;
            data['fio']['value'] = contact.fio;
            data['fio']['isValid'] = true;
            data['sum']['value'] = `${(+deal.summa).toLocaleString()}`;
            data['sum']['isValid'] = true;
            const birthday = contact.birthday.split(/[-T]/);
            birthday.length = 3;
            data['dateBirth']['value'] = birthday.reverse().join('.');
            data['dateBirth']['isValid'] = true;
            data['phone']['value'] = maskPhone(contact.phone);
            data['phone']['isValid'] = true;
            data['email']['value'] = contact.email;
            data['email']['isValid'] = true;
            data['city']['value'] = deal.city_credit;
            data['city']['isValid'] = true;
            data['cityCreditKladr']['value'] = deal.city_credit_kladr || '';
            data['cityCreditKladr']['isValid'] = true;
            data['regionCredit']['value'] = deal.region_credit || '';
            data['regionCredit']['isValid'] = true;

            if (deal.income) {
                step = 3;
                data['income']['value'] = deal.income;
                data['income']['isValid'] = true;
                data['employment']['value'] = deal.employment;
                data['employment']['isValid'] = true;
                data['methodConfirmingIncome']['value'] = deal.proof_income;
                data['methodConfirmingIncome']['isValid'] = true;
                data['creditHistory']['value'] = deal.credit_history;
                data['creditHistory']['isValid'] = true;
                data['education']['value'] = deal.education;
                data['education']['isValid'] = true;
                data['own']['value'] = deal.property;
                data['own']['isValid'] = true;
                data['startWorkLastPlaceMonth']['value'] = `${+deal.start_work.split('-')[1]}`;
                data['startWorkLastPlaceMonth']['isValid'] = true;
                data['startWorkLastPlaceYear']['value'] = deal.start_work.split('-')[0];
                data['startWorkLastPlaceYear']['isValid'] = true;

                if (deal.work_name) {
                    step = 4;
                    data['organization']['value'] = deal.work_name;
                    data['organization']['isValid'] = true;
                    data['workInn']['value'] = deal.work_inn;
                    data['workInn']['isValid'] = true;
                    data['workHead']['value'] = deal.work_head;
                    data['workHead']['isValid'] = true;
                    data['workCity']['value'] = deal.work_city;
                    data['workCity']['isValid'] = true;
                    data['workStreet']['value'] = deal.work_street;
                    data['workStreet']['isValid'] = true;
                    data['workHouse']['value'] = deal.work_house;
                    data['workHouse']['isValid'] = true;
                    data['workOpf']['value'] = deal.work_opf || '';
                    data['workOpf']['isValid'] = true;
                    data['workRegionKladr']['value'] = deal.work_region_kladr || '';
                    data['workRegionKladr']['isValid'] = true;
                    data['workRegion']['value'] = deal.work_region || '';
                    data['workRegion']['isValid'] = true;
                    data['okvedMain']['value'] = deal.okved_main || '';
                    data['okvedMain']['isValid'] = true;
                    data['workCityKladr']['value'] = deal.work_city_kladr || '';
                    data['workCityKladr']['isValid'] = true;
                    data['workStreetKladr']['value'] = deal.work_street_kladr || '';
                    data['workStreetKladr']['isValid'] = true;
                    data['workHouseKladr']['value'] = deal.work_home_kladr || '';
                    data['workHouseKladr']['isValid'] = true;
                    data['workPhone']['value'] = maskPhone(deal.work_phone);
                    data['workPhone']['isValid'] = true;
                    data['positionWork']['value'] = deal.work_position;
                    data['positionWork']['isValid'] = true;
                    data['totalWorkExperience']['value'] = deal.experience;
                    data['totalWorkExperience']['isValid'] = true;
                    data['familyStatus']['value'] = deal.family_status;
                    data['familyStatus']['isValid'] = true;
                    data['amountChildren']['value'] = deal.children;
                    data['amountChildren']['isValid'] = true;
                    data['loansPerMonth']['value'] = deal.spending;
                    data['loansPerMonth']['isValid'] = true;
                    data['placeResidence']['value'] = contact.address;
                    data['placeResidence']['isValid'] = true;
                    data['registrationСode']['value'] = contact.registration_code;
                    data['registrationСode']['isValid'] = true;
                    data['registrationRegion']['value'] = contact.registration_region;
                    data['registrationRegion']['isValid'] = true;
                    data['registrationCity']['value'] = contact.registration_city;
                    data['registrationCity']['isValid'] = true;
                    data['registrationStreet']['value'] = contact.registration_street;
                    data['registrationStreet']['isValid'] = true;
                    data['registrationHome']['value'] = contact.registration_home;
                    data['registrationHome']['isValid'] = true;
                    data['registrationFlat']['value'] = contact.registration_flat;
                    data['registrationFlat']['isValid'] = true;
                    data['registrationHomeKladr']['value'] = contact.registration_home_kladr || '';
                    data['registrationHomeKladr']['isValid'] = true;
                }
            }
        }

        setFullStep(dispatch, data);
        setStep(step);
    };

    useEffect(() => {
        if (slug) {
            const formdata = new FormData();
            formdata.append('id', slug);
            postEncrypt(formdata)
                .then((res) => linkData(res))
                .catch((error) => {
                    console.error('error', error);
                });
        } else {
            startLocalStorage();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        let points = 0;
        let next = 0;
        for (const key in state) {
            if (state[key].isValid === true) {
                points += state[key].points ?? 0;
            } else {
                next = state[key].points;
            }
        }
        if (points !== currentPoints) {
            setCurrentPoints(points);
        }
        setNextPoints(next);
    }, [currentPoints, state]);

    useEffect(() => {
        if (searchParams.has('lead_id')) {
            const leadId = searchParams.get('lead_id');
            if (leadId) {
                localStorage.setItem('leadId', leadId);
            }
        }
    }, [searchParams]);

    useEffect(() => {
        function setClientId() {
            window?.ym(90562195, 'getClientID', (clientID: string) => {
                setYandexMetrikaClientId(clientID);
            });
        }
        if (!window?.ym) {
            addEventListener('yacounter90562195inited', setClientId);
        } else {
            setClientId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => removeEventListener('yacounter90562195inited', setClientId);
    }, [yandexMetrikaClientId]);

    const [step, setStep] = useState<1 | 2 | 3 | 4 | 5>(1);

    const { maskDateBirth, validationDateBirth, maskPhone, validationPhone, validationFio } =
        useStep1();

    const onIsValid = (field: any) => {
        return field.isValid === false ? field.errorMessages.default : field.isValid;
    };

    const onBackStep = (step: 1 | 2 | 3 | 4 | 5) => {
        setStep(step);
        // localStorage.setItem('step', `${step}`);

        scroll.scrollTo(formRef.current.offsetTop);
        animStep();
    };

    const animStep = () => {
        const target = formRef.current;
        target.classList.add('anim-opacity');
        setTimeout(() => {
            target.classList.remove('anim-opacity');
        }, 1000);
    };

    const onNextStep1 = async () => {
        let isNext = true;
        let focusField = '';

        if (fio.isValid !== true) {
            isNext = false;
            focusField = 'fio';
            setFio(dispatch, fio, fio.value, onIsValid(fio));
        }
        if (sum.value === '') {
            setSum(dispatch, sum, '500000', true);
        }

        if (dateBirth.isValid !== true) {
            isNext = false;
            focusField = focusField || 'dateBirth';
            const isValid = validationDateBirth(dateBirth.errorMessages, dateBirth.value);
            setDateBirth(dispatch, dateBirth, maskDateBirth(dateBirth.value), isValid);
        }
        if (phone.isValid !== true) {
            isNext = false;
            focusField = focusField || 'phone';
            const isValid = validationPhone(phone.errorMessages, phone.value);
            setPhone(dispatch, phone, maskPhone(phone.value), isValid);
        }
        if (email.isValid !== true) {
            isNext = false;
            focusField = focusField || 'email';
            setEmail(dispatch, email, email.value, onIsValid(email));
        }
        if (city.isValid !== true) {
            isNext = false;
            focusField = focusField || 'city';
            setCity(dispatch, city, city.value, onIsValid(city), city.isDadata);
            setCityCreditKladr(dispatch, cityCreditKladr, '', false, false);
            setRegionCredit(dispatch, regionCredit, '', false, false);
        }
        if (checkbox1.isValid !== true) {
            isNext = false;
        }
        if (checkbox2.isValid !== true) {
            isNext = false;
        }

        if (isNext) {
            localStorage.setItem('step', '1');
            localStorage.setItem('fio', fio.value);
            localStorage.setItem('sum', sum.value);
            localStorage.setItem('dateBirth', dateBirth.value);
            localStorage.setItem('phone', phone.value);
            localStorage.setItem('email', email.value);
            localStorage.setItem('city', city.value);
            localStorage.setItem('cityCreditKladr', cityCreditKladr.value);
            localStorage.setItem('cregionCredit', regionCredit.value);
            localStorage.setItem('mailCheckbox', `${checkbox2.isValid}`);

            const w: any = window;

            setStep(2);
            scroll.scrollTo(formRef.current.offsetTop);

            animStep();

            const formData: any = new FormData();

            const transformSumm = (sum: string): number => {
                const number = Number(`${sum.replace(/\s/g, '')}`);

                if (number < 10000) {
                    return 10000;
                }
                if (number >= 10000 && number <= 100000) {
                    return 100000;
                }
                if (number >= 100001 && number <= 149999) {
                    return 150000;
                }
                return number;
            };

            // eslint-disable-next-line no-irregular-whitespace
            formData.append('summa', sum.value === '' ? '500000' : transformSumm(sum.value));
            formData.append('yandex_client_id', `${yandexMetrikaClientId}`);
            formData.append('fio', `${fio.value}`);

            const arrFio = fio.value.split(' ');
            const newDataFio = {
                firstname: arrFio[1],
                surname: arrFio[0],
                patronymic: `${arrFio[2]} ${arrFio.length === 4 ? arrFio[3] : ''}`,
                gender: 'UNKNOWN',
            };
            const options = {
                method: 'POST',
                mode: 'cors' as RequestMode,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Token f7cd59d270fa2905f74cf38f74981c3abbe346c8',
                },
                body: JSON.stringify({ query: fio.value, count: 1 }),
            };
            await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio', options)
                .then((response) => response.text())
                .then((result) => {
                    const d = JSON.parse(result);
                    if (
                        d.suggestions &&
                        d.suggestions.length === 1 &&
                        d.suggestions[0]?.data?.name &&
                        d.suggestions[0]?.data?.surname &&
                        d.suggestions[0]?.data?.patronymic
                    ) {
                        const dFio = d.suggestions[0].data;
                        newDataFio['firstname'] = dFio.name;
                        newDataFio['surname'] = dFio.surname;
                        newDataFio['patronymic'] = dFio.patronymic;
                        newDataFio['gender'] = dFio.gender;
                    }
                })
                .catch((error) => {
                    console.error('error', error);
                    w.ym(90562195, 'reachGoal', 'error-step1-dadata');
                });

            formData.append('firstname', `${newDataFio.firstname}`);
            formData.append('surname', `${newDataFio.surname}`);
            formData.append('patronymic', `${newDataFio.patronymic}`);
            formData.append('gender', `${newDataFio.gender}`);

            const arrDate = dateBirth.value.split('.');

            formData.append('birthdate', `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`);
            formData.append('phone', `${phone.value.replace(/[ ()-]/g, '')}`);
            formData.append('email', `${email.value}`);
            formData.append('city_credit', `${city.value.replace(/\s*\(бывш.*\)/, '')}`);
            formData.append('city_credit_kladr', `${cityCreditKladr.value}`);
            formData.append('region_credit', `${regionCredit.value}`);
            formData.append('base_url', window.location.hostname);

            const deaiId = localStorage.getItem('deaiId');

            if (deaiId) {
                formData.append('step', '1');
                formData.append('id', +deaiId);
                updateDeal(formData)
                    .then(() => {
                        w.ym(90562195, 'reachGoal', 'step1');
                    })
                    .catch((error) => {
                        console.error('error', error);
                        w.ym(90562195, 'reachGoal', 'error-send');
                        w.ym(90562195, 'reachGoal', 'error-step1');
                        setError400(true);
                        scroll.scrollTo(formRef.current.offsetTop);
                    });
            } else {
                searchParams.forEach((value: any, key: any) => {
                    if (key.startsWith('utm_')) {
                        formData.append(key, `${value}`);
                    }
                });

                if (localStorage.getItem('leadId')) {
                    formData.append('lead_id', localStorage.getItem('leadId'));
                }

                createDeal(formData)
                    .then((res) => {
                        w.ym(90562195, 'reachGoal', 'step1');
                        localStorage.setItem('deaiId', res?.data?.result?.ID);
                    })
                    .catch((error) => {
                        console.error('error', error);
                        w.ym(90562195, 'reachGoal', 'error-send');
                        w.ym(90562195, 'reachGoal', 'error-create-deal');
                        setError400(true);
                        scroll.scrollTo(formRef.current.offsetTop);
                    });
            }
        }

        scrollIntoView(focusField);
    };

    const onNextStep2 = () => {
        let isNext = true;
        let focusField = '';

        if (income.value === 'none') {
            isNext = false;
            focusField = 'income';
            setIncome(dispatch, income, income.value, income.errorMessages.choice);
        }
        if (employment.value === 'none') {
            isNext = false;
            focusField = focusField || 'employment';
            setEmployment(dispatch, employment, employment.value, employment.errorMessages.choice);
        }
        if (methodConfirmingIncome.value === 'none') {
            isNext = false;
            focusField = focusField || 'methodConfirmingIncome';
            setMethodConfirmingIncome(
                dispatch,
                methodConfirmingIncome,
                methodConfirmingIncome.value,
                methodConfirmingIncome.errorMessages.choice,
            );
        }
        if (creditHistory.value === 'none') {
            isNext = false;
            focusField = focusField || 'creditHistory';
            setCreditHistory(
                dispatch,
                creditHistory,
                creditHistory.value,
                creditHistory.errorMessages.choice,
            );
        }
        if (education.value === 'none') {
            isNext = false;
            focusField = focusField || 'education';
            setEducation(dispatch, education, education.value, education.errorMessages.choice);
        }
        if (own.value === 'none') {
            isNext = false;
            focusField = focusField || 'own';
            setOwn(dispatch, own, own.value, own.errorMessages.choice);
        }
        if (
            startWorkLastPlaceMonth.value === 'none' &&
            employment.value !== '62' &&
            employment.value !== '64' &&
            employment.value !== '240'
        ) {
            isNext = false;
            focusField = focusField || 'startWorkLastPlaceMonth';
            setStartWorkLastPlaceMonth(
                dispatch,
                startWorkLastPlaceMonth,
                startWorkLastPlaceMonth.value,
                startWorkLastPlaceMonth.errorMessages.choice,
            );
        }
        if (
            startWorkLastPlaceYear.value === 'none' &&
            employment.value !== '62' &&
            employment.value !== '64' &&
            employment.value !== '240'
        ) {
            isNext = false;
            focusField = focusField || 'startWorkLastPlaceYear';
            setStartWorkLastPlaceYear(
                dispatch,
                startWorkLastPlaceYear,
                startWorkLastPlaceYear.value,
                startWorkLastPlaceYear.errorMessages.choice,
            );
        }

        if (isNext) {
            localStorage.setItem('step', '2');

            localStorage.setItem('income', income.value);
            localStorage.setItem('employment', employment.value);
            localStorage.setItem('methodConfirmingIncome', methodConfirmingIncome.value);
            localStorage.setItem('creditHistory', creditHistory.value);
            localStorage.setItem('education', education.value);
            localStorage.setItem('own', own.value);
            localStorage.setItem('startWorkLastPlaceMonth', startWorkLastPlaceMonth.value);
            localStorage.setItem('startWorkLastPlaceYear', startWorkLastPlaceYear.value);

            const formData: any = new FormData();

            const deaiId = localStorage.getItem('deaiId');
            formData.append('step', '2');
            formData.append('id', deaiId ? +deaiId : '');

            formData.append('income', `${income.value}`);
            formData.append('employment', `${employment.value}`);

            formData.append('credit_history', `${creditHistory.value}`);
            formData.append('education', `${education.value}`);
            formData.append('property', `${own.value}`);

            formData.append('proof_income', `${methodConfirmingIncome.value}`);

            if (
                employment.value !== '62' &&
                employment.value !== '64' &&
                employment.value !== '240'
            ) {
                formData.append(
                    'start_work',
                    `${startWorkLastPlaceYear.value}-${startWorkLastPlaceMonth.value}-01`,
                );
            }

            const w: any = window;

            updateDeal(formData)
                .then(() => {
                    w.ym(90562195, 'reachGoal', 'step2');
                })
                .catch((error) => {
                    console.error('error', error);
                    w.ym(90562195, 'reachGoal', 'error-send');
                    w.ym(90562195, 'reachGoal', 'error-step2');
                    setError400(true);
                    scroll.scrollTo(formRef.current.offsetTop);
                });

            scroll.scrollTo(formRef.current.offsetTop);
            animStep();

            if (flags?.disableCustomSteps) {
                setStep(3);
                animStep();
            } else {
                setIsLoading(true);

                setTimeout(() => {
                    setStep(3);
                    animStep();
                    setIsLoading(false);
                }, 2000);
            }
        }

        scrollIntoView(focusField);
    };

    const { validateWorkAddress } = useStep3();

    const onNextStep3 = async () => {
        let isNext = true;
        let focusField = '';

        if (
            organization.isValid !== true &&
            employment.value !== '62' &&
            employment.value !== '64' &&
            employment.value !== '238' &&
            employment.value !== '240'
        ) {
            isNext = false;
            focusField = 'organization';
            setOrganization(
                dispatch,
                organization,
                organization.value,
                onIsValid(organization),
                organization.isDadata,
            );
            setOrganizationDependentFields(dispatch, {
                workInn: '',
                workHead: '',
                workCity: '',
                workStreet: '',
                workHouse: '',
                workOpf: '',
                workRegionKladr: '',
                workRegion: '',
                okvedMain: '',
                workCityKladr: '',
                workStreetKladr: '',
                workHouseKladr: '',
            });
        }
        if (
            organization.isValid === true &&
            workAddress.isValid !== true &&
            employment.value !== '62' &&
            employment.value !== '64' &&
            employment.value !== '238' &&
            employment.value !== '240'
        ) {
            isNext = false;
            focusField = focusField || 'workAddress';
            const isValid = validateWorkAddress(
                workAddress.errorMessages,
                {
                    value: workAddress.value,
                    city: workCity.value,
                    street: workStreet.value,
                    house: workHouse.value,
                },
                true,
            );

            setWorkAddress(dispatch, workAddress, workAddress.value, isValid, false);
            setWorkAddressDependentFields(dispatch, {
                workCity: workCity.value,
                workStreet: workStreet.value,
                workHouse: workHouse.value,
                workRegionKladr: '',
                workRegion: '',
                workCityKladr: '',
                workStreetKladr: '',
                workHouseKladr: '',
            });
        }

        if (
            workPhone.isValid !== true &&
            employment.value !== '62' &&
            employment.value !== '64' &&
            employment.value !== '238' &&
            employment.value !== '240'
        ) {
            isNext = false;
            focusField = focusField || 'workPhone';
            setWorkPhone(dispatch, workPhone, workPhone.value, onIsValid(workPhone));
        }

        if (totalWorkExperience.value === 'none') {
            isNext = false;
            focusField = focusField || 'totalWorkExperience';
            setTotalWorkExperience(
                dispatch,
                totalWorkExperience,
                totalWorkExperience.value,
                totalWorkExperience.errorMessages.choice,
            );
        }
        if (familyStatus.value === 'none') {
            isNext = false;
            focusField = focusField || 'familyStatus';
            setFamilyStatus(
                dispatch,
                familyStatus,
                familyStatus.value,
                familyStatus.errorMessages.choice,
            );
        }

        if (amountChildren.value === '') {
            isNext = false;
            focusField = focusField || 'amountChildren';
            setAmountChildren(
                dispatch,
                amountChildren,
                amountChildren.value,
                amountChildren.errorMessages.default,
            );
        } else {
            setAmountChildren(dispatch, amountChildren, amountChildren.value, true);
        }

        if (loansPerMonth.value === '') {
            isNext = false;
            focusField = focusField || 'loansPerMonth';
            setLoansPerMonth(
                dispatch,
                loansPerMonth,
                loansPerMonth.value,
                loansPerMonth.errorMessages.default,
            );
        } else {
            setLoansPerMonth(dispatch, loansPerMonth, loansPerMonth.value, true);
        }

        if (placeResidence.isValid !== true) {
            isNext = false;
            focusField = focusField || 'placeResidence';
            setPlaceResidence(
                dispatch,
                placeResidence,
                placeResidence.value,
                onIsValid(placeResidence),
                placeResidence.isDadata,
            );
            setPlaceResidenceDependentFields(dispatch, {
                registrationСode: '',
                registrationRegion: '',
                registrationCity: '',
                registrationStreet: '',
                registrationHome: '',
                registrationFlat: '',
                registrationHomeKladr: '',
            });
        }

        if (isNext) {
            localStorage.setItem('step', '3');
            localStorage.setItem('organization', organization.value);
            localStorage.setItem('workAddress', workAddress.value);
            localStorage.setItem('workInn', workInn.value);
            localStorage.setItem('workHead', workHead.value);
            localStorage.setItem('workCity', workCity.value);
            localStorage.setItem('workStreet', workStreet.value);
            localStorage.setItem('workHouse', workHouse.value);
            localStorage.setItem('workOpf', workOpf.value);
            localStorage.setItem('workRegionKladr', workRegionKladr.value);
            localStorage.setItem('workRegion', workRegion.value);
            localStorage.setItem('okvedMain', okvedMain.value);
            localStorage.setItem('workCityKladr', workCityKladr.value);
            localStorage.setItem('workStreetKladr', workStreetKladr.value);
            localStorage.setItem('workHouseKladr', workHouseKladr.value);
            localStorage.setItem('workPhone', workPhone.value);
            localStorage.setItem('totalWorkExperience', totalWorkExperience.value);
            localStorage.setItem('familyStatus', familyStatus.value);
            localStorage.setItem('amountChildren', amountChildren.value);
            localStorage.setItem('loansPerMonth', loansPerMonth.value);
            localStorage.setItem('placeResidence', placeResidence.value);
            localStorage.setItem('registrationСode', registrationСode.value);
            localStorage.setItem('registrationRegion', registrationRegion.value);
            localStorage.setItem('registrationCity', registrationCity.value);
            localStorage.setItem('registrationStreet', registrationStreet.value);
            localStorage.setItem('registrationHome', registrationHome.value);
            localStorage.setItem('registrationFlat', registrationFlat.value);
            localStorage.setItem('registrationHomeKladr', registrationHomeKladr.value);

            // const win = window;
            // win.ym(90562195, 'reachGoal', 'step3');
            // setStep(4);
            // scroll.scrollTo(formRef.current.offsetTop);
            // animStep();

            const formData: any = new FormData();

            const deaiId = localStorage.getItem('deaiId');
            formData.append('step', '3');
            formData.append('id', deaiId ? +deaiId : '');

            setIsLoading(true);
            const secundsBefore: any = new Date();
            animStep();
            scroll.scrollTo(formRef.current.offsetTop);

            const changeWorkPos = () => {
                const value = employment.value;
                switch (value) {
                    case '52':
                        return '106';
                    case '54':
                        return '106';
                    case '56':
                        return '104';
                    case '58':
                        return '104';
                    case '60':
                        return '106';
                    case '238':
                        return '106';
                }
                return 'none';
            };

            if (
                employment.value !== '62' &&
                employment.value !== '64' &&
                employment.value !== '238' &&
                employment.value !== '240'
            ) {
                formData.append('work_name', `${organization.value}`);
                formData.append('work_address', `${workAddress.value}`);
                formData.append('work_inn', `${workInn.value}`);
                formData.append('work_head', `${workHead.value}`);
                formData.append('work_city', `${workCity.value}`);
                formData.append('work_street', `${workStreet.value}`);
                formData.append('work_house', `${workHouse.value}`);
                formData.append('work_opf', `${workOpf.value}`);
                formData.append('work_region_kladr', `${workRegionKladr.value}`);
                formData.append('okved_main', `${okvedMain.value}`);
                formData.append('work_city_kladr', `${workCityKladr.value}`);
                formData.append('work_street_kladr', `${workStreetKladr.value}`);
                formData.append('work_home_kladr', `${workHouseKladr.value}`);
                formData.append('work_phone', `${workPhone.value}`);
                formData.append('work_position', changeWorkPos());
                formData.append('work_region', `${workRegion.value}`);
            }

            if (employment.value === '238') {
                formData.append('work_address', `${workAddress.value}`);
                formData.append('work_city', `${workCity.value}`);
                formData.append('work_street', `${workStreet.value}`);
                formData.append('work_house', `${workHouse.value}`);
                formData.append('work_region_kladr', `${workRegionKladr.value}`);
                formData.append('work_city_kladr', `${workCityKladr.value}`);
                formData.append('work_street_kladr', `${workStreetKladr.value}`);
                formData.append('work_home_kladr', `${workHouseKladr.value}`);
                formData.append('work_phone', `${workPhone.value}`);
                formData.append('work_position', changeWorkPos());
            }

            formData.append('experience', `${totalWorkExperience.value}`);
            formData.append('family_status', `${familyStatus.value}`);
            formData.append('children', `${amountChildren.value.replace(/[\D]/g, '')}`);
            formData.append('spending', `${loansPerMonth.value.replace(/[\D]/g, '')}`);
            formData.append('address', `${placeResidence.value.replace(/[/]/g, '')}`);
            formData.append('registration_code', `${registrationСode.value}`);
            formData.append('registration_region', `${registrationRegion.value}`);
            formData.append('registration_city', `${registrationCity.value}`);
            formData.append('registration_street', `${registrationStreet.value || ''}`);
            formData.append('registration_home', `${registrationHome.value}`);
            formData.append('registration_flat', `${registrationFlat.value}`);
            formData.append('registration_home_kladr', `${registrationHomeKladr.value}`);

            const w: any = window;

            updateDeal(formData)
                .then(() => {
                    w.ym(90562195, 'reachGoal', 'step3');
                    const secundsAfter: any = new Date();
                    const diff: any = secundsAfter - secundsBefore;
                    if (diff / 1000 < 2) {
                        setTimeout(() => {
                            setIsLoading(false);
                            setStep(4);
                            scroll.scrollTo(formRef.current.offsetTop);
                            animStep();
                        }, 2 - diff / 1000);
                    } else {
                        setIsLoading(false);
                        setStep(4);
                        scroll.scrollTo(formRef.current.offsetTop);
                        animStep();
                    }
                })
                .catch((error) => {
                    console.error('error', error);
                    w.ym(90562195, 'reachGoal', 'error-send');
                    w.ym(90562195, 'reachGoal', 'error-step3');
                    setError400(true);
                    scroll.scrollTo(formRef.current.offsetTop);
                });
        }

        scrollIntoView(focusField);
    };

    const { validationContactPhone } = useStep4();

    const onNextStep4 = async () => {
        let isNext = true;
        let focusField = '';

        if (passportNumberSeries.isValid !== true) {
            isNext = false;
            focusField = 'passportNumberSeries';
            setPassportNumberSeries(
                dispatch,
                passportNumberSeries,
                passportNumberSeries.value,
                onIsValid(passportNumberSeries),
            );
        }

        if (dateOfIssue.isValid !== true) {
            isNext = false;
            focusField = focusField || 'dateOfIssue';
            setDateOfIssue(dispatch, dateOfIssue, dateOfIssue.value, onIsValid(dateOfIssue));
        }

        if (unitСode.isValid !== true) {
            isNext = false;
            focusField = focusField || 'unitСode';
            setUnitСode(dispatch, unitСode, unitСode.value, onIsValid(unitСode));
        }

        if (issuedBy.isValid !== true) {
            isNext = false;
            focusField = focusField || 'issuedBy';
            setIssuedBy(dispatch, issuedBy, issuedBy.value, onIsValid(issuedBy));
        }

        if (placeOfBirth.isValid !== true) {
            isNext = false;
            focusField = focusField || 'placeOfBirth';
            setPlaceOfBirth(
                dispatch,
                placeOfBirth,
                placeOfBirth.value,
                onIsValid(placeOfBirth),
                placeOfBirth.isDadata,
            );
        }

        if (contactPhone.isValid !== true) {
            const isValid = validationContactPhone(
                contactPhone.errorMessages,
                contactPhone.value,
                contactPhone.value === phone.value || contactPhone.value === workPhone.value,
            );

            isNext = false;
            focusField = focusField || 'contactPhone';
            setContactPhone(dispatch, contactPhone, contactPhone.value, isValid);
        }

        if (contactPhone.value.length === 18 && contactPerson.value !== '226') {
            if (contactPersonFio.isValid !== true) {
                isNext = false;
                focusField = focusField || 'contactPersonFio';
                setContactPersonFio(
                    dispatch,
                    contactPersonFio,
                    contactPersonFio.value,
                    validationFio(contactPersonFio.errorMessages, contactPersonFio.value),
                );
            }
        }

        if (isNext) {
            const formData: any = new FormData();

            const deaiId = localStorage.getItem('deaiId');
            formData.append('step', '4');
            formData.append('id', deaiId ? +deaiId : '');

            formData.append('passport', `${passportNumberSeries.value}`);
            const formateDate = dateOfIssue.value.split('.');
            formData.append(
                'date_passport',
                `${formateDate[2]}-${formateDate[1]}-${formateDate[0]}`,
            );
            formData.append('code', `${unitСode.value}`);
            formData.append('code_name', `${issuedBy.value}`);
            formData.append('passport_place', `${placeOfBirth.value.replace(/\s*\(бывш.*\)/, '')}`);
            formData.append('codeword', `${dateBirth.value.substring(dateBirth.value.length - 4)}`);
            formData.append('date_permit', `${dateOfIssue.value}`);

            if (contactPhone.value.length === 18) {
                formData.append('contact_phone', `${contactPhone.value}`);
                formData.append('contact_person', `${contactPerson.value}`);
                if (contactPersonFio.value) {
                    formData.append('contact_fio', `${contactPersonFio.value}`);
                    formData.append('contact_firstname', `${contactPersonFirstname.value}`);
                    formData.append('contact_surname', `${contactPersonSurname.value}`);
                    formData.append('contact_patronymic', `${contactPersonPatronymic.value}`);
                }
            }

            const w: any = window;

            setStep(5);
            scroll.scrollTo(formRef.current.offsetTop);
            animStep();

            const tab = window.open(`/prepare/${Date.now()}`, '_blank');
            if (tab) {
                tab.onload = () => {
                    document.title = `Обработка заявки ${deaiId}`;
                };
            }

            updateDeal(formData)
                .then((value) => {
                    localStorage.clear();
                    let url = '';
                    let blankUrl = '';

                    const cookies = document.cookie.split(';');

                    for (let i = 0; i < cookies.length; i++) {
                        const cookie = cookies[i];
                        const eqPos = cookie.indexOf('=');
                        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                        document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
                    }

                    w.ym(90562195, 'reachGoal', 'step4');
                    if ('_tmr' in w) {
                        w._tmr.push({ type: 'reachGoal', id: 3308705, goal: 'lead' });
                    }

                    if (w?.VK && w?.VK?.Goal) {
                        w.VK.Goal('lead');
                    }

                    localStorage.setItem('step', 'null');

                    if (value?.data?.status === 'redirect') {
                        if (value?.data?.link == '') {
                            url = `${window.location.protocol}//${window.location.host}/sms?dealId=${deaiId}`;
                            localStorage.setItem('phone', phone.value);
                        } else {
                            url = value?.data?.link;
                        }

                        blankUrl = '/vitrina-b/';
                    } else {
                        if (tab) {
                            url = '/vitrina-b/';
                            blankUrl =
                                'https://vsezaimy-online.ru/v/JXYOW/?sub_id1=vitrina&sub_id2=Vitrina_kreditov_i_kart&sub_id5=Vitrina_kreditov_i_kart';
                        }
                    }

                    if (tab) {
                        tab.location = url;
                        tab.focus();
                    }

                    window.location.href = blankUrl;
                })
                .catch((error) => {
                    if (tab) {
                        tab.close();
                    }
                    console.error('error', error);
                    w.ym(90562195, 'reachGoal', 'error-send');
                    w.ym(90562195, 'reachGoal', 'error-step4');
                    w.ym(90562195, 'reachGoal', 'step4');
                    setError400(true);
                    scroll.scrollTo(formRef.current.offsetTop);
                });
        }

        scrollIntoView(focusField);
    };

    const stepRender = (step: 1 | 2 | 3 | 4 | 5) => {
        if (step === 1) {
            return <FormStep1 setStep={onNextStep1} />;
        }
        if (step === 2) {
            return <FormStep2 setStep={onNextStep2} setBack={onBackStep} />;
        }
        if (step === 3) {
            return <FormStep3 setStep={onNextStep3} setBack={onBackStep} />;
        }
        if (step === 4) {
            return <FormStep4 setStep={onNextStep4} setBack={onBackStep} />;
        }
    };

    const stepView = stepRender(step);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (step === 1) {
            onNextStep1();
        } else if (step === 2) {
            onNextStep2();
        } else if (step === 3) {
            onNextStep3();
        } else if (step === 4) {
            onNextStep4();
        }
    };

    const contentView =
        step === 5 ? (
            <FormStep5View />
        ) : (
            <FormQuestView
                isError={error400}
                isLoading={isLoading}
                onSubmit={onSubmit}
                formRef={formRef}
                currentPoints={currentPoints}
                nextPoints={nextPoints}
                totalPoints={totalPoints}
                title={
                    flags?.disableCustomSteps
                        ? 'ПОЛУЧИТЕ ОДОБРЕНИЕ КРЕДИТА ЗА 30 МИНУТ'
                        : getTitle(step, sum.value)
                }
                subTitle={
                    flags?.disableCustomSteps
                        ? 'Заполните анкету полностью, чтобы получить максимальное количество предложений!'
                        : getSubTitle(step)
                }
                step={step}
            >
                {stepView}
            </FormQuestView>
        );

    return contentView;
};

export default FormQuest;
