import switcher from 'ai-switcher-translit';
import { useContext, useEffect, useRef, useState } from 'react';
import { RenderSuggestion } from 'react-autosuggest';
import HighlightWords from 'react-highlight-words';

import { setPlaceOfBirth } from '../../../../actions/formQuest';
import { useDadataAddress } from '../../../../hooks/dadataApiHooks';
import { FormQuestContext } from '../../../../reducer/reducer';
import { SuggestInput } from '../../suggestInput/SuggestInput';

/** параметры дадаты */
const locations = [
    { country_iso_code: 'AZ' },
    { country_iso_code: 'AM' },
    { country_iso_code: 'BY' },
    { country_iso_code: 'KZ' },
    { country_iso_code: 'KG' },
    { country_iso_code: 'MD' },
    { country_iso_code: 'RU' },
    { country_iso_code: 'TJ' },
    { country_iso_code: 'UZ' },
];
const params = {
    count: 5,
    locations,
    from_bound: { value: 'city' },
    to_bound: { value: 'settlement' },
};

/** маппер для получения suggestionValue */
const getSuggestionValue = (suggestion: any) => suggestion.value;

/** рендер подсказки в списке */
const renderSuggestion: RenderSuggestion<any> = (suggestion, params) => {
    const value = Array.isArray(suggestion.data.history_values)
        ? `${suggestion.value} (бывш. ${suggestion.data.history_values[0]})`
        : suggestion.value;

    return (
        <div>
            <HighlightWords
                searchWords={[
                    new RegExp(
                        params.query.replace(/([!@#$%^&*()+=\\[\]\\';,./{}|":<>?~_-])/g, '\\$1'),
                        'g',
                    ),
                ]}
                textToHighlight={value}
            />
        </div>
    );
};

export const PlaceOfBirthSuggest = () => {
    const [focused, setFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const { data, trigger } = useDadataAddress(params);

    const { state, dispatch } = useContext(FormQuestContext);
    const { placeOfBirth } = state;

    const handleChange = (e: any, { newValue }: any) => {
        setPlaceOfBirth(dispatch, placeOfBirth, newValue, false, false);
    };

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        trigger(value);
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (e: any, data: any) => {
        const place = data.suggestion.data.city || data.suggestion.data.settlement;
        const placeOfBirthValue = Array.isArray(data.suggestion.data.history_values)
            ? `${place} (бывш. ${data.suggestion.data.history_values[0]})`
            : place;

        setPlaceOfBirth(
            dispatch,
            placeOfBirth,
            switcher.getSwitch(placeOfBirthValue, {
                type: 'retranslit',
            }),
            true,
            true,
        );
    };

    useEffect(() => {
        if (data) {
            setSuggestions(data.suggestions);
        }
    }, [data]);

    const inputProps = {
        placeholder: 'Населенный пункт',
        value: placeOfBirth.value,
        onChange: handleChange,
        onFocus: () => {
            setFocused(true);

            if (!focused && inputRef.current) {
                const r = inputRef.current;

                setTimeout(() => {
                    r.setSelectionRange(placeOfBirth.value.length, placeOfBirth.value.length);
                }, 10);
            }
        },
        onBlur: () => {
            setPlaceOfBirth(
                dispatch,
                placeOfBirth,
                switcher.getSwitch(placeOfBirth.value, {
                    type: 'retranslit',
                }),
                placeOfBirth.value ? true : placeOfBirth.errorMessages.default,
                true,
            );
            setFocused(false);
        },
        ref: inputRef,
    };

    return (
        <SuggestInput
            label="Место рождения"
            inputProps={inputProps}
            isValid={placeOfBirth.isValid}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
        />
    );
};
