import { FC, ReactElement, useState } from 'react';
import Autosuggest, { AutosuggestPropsSingleSection } from 'react-autosuggest';

import theme from './theme.module.css';

interface Suggestion {
    value: string;
}

interface SuggestInputProps
    extends Pick<
        AutosuggestPropsSingleSection<Suggestion>,
        | 'onSuggestionsFetchRequested'
        | 'onSuggestionsClearRequested'
        | 'onSuggestionSelected'
        | 'alwaysRenderSuggestions'
        | 'getSuggestionValue'
        | 'renderSuggestion'
        | 'suggestions'
        | 'inputProps'
    > {
    label: string;
    isValid: string | boolean;
    inputBadge?: ReactElement | null;
}

export const SuggestInput: FC<SuggestInputProps> = ({
    label,
    isValid,
    inputBadge,
    inputProps,
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
}) => {
    const [focused, setFocused] = useState(false);

    const renderInputComponent = ({ onFocus, onBlur, ...inputProps }: any) => {
        return (
            <div
                className={`input ${
                    isValid ? (isValid === true ? 'is-valid-ok' : 'is-valid-error') : ''
                }`}
            >
                <div className="input__info row jc-sb">
                    <div className={`input__title ${focused ? 'active' : ''}`}>{label}</div>
                    <div className="input__error">{typeof isValid === 'string' ? isValid : ''}</div>
                </div>
                <div className="input__input">
                    <input
                        onFocus={(e) => {
                            setFocused(true);
                            onFocus(e);
                        }}
                        onBlur={(e) => {
                            setFocused(false);
                            onBlur(e);
                        }}
                        className="ym-record-keys"
                        {...inputProps}
                    />
                    {inputBadge}
                </div>
            </div>
        );
    };

    return (
        <Autosuggest
            inputProps={inputProps}
            suggestions={suggestions}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            renderInputComponent={renderInputComponent}
            theme={theme}
        />
    );
};
