import { request } from '../utils';

const useSend = () => {
    const _baseUrl = 'https://bx2.reshbank.online/api/';
    const _token = 'UaSGc1pkqc8tw11AIvG1';

    const send = async (url: string, data: any, method = 'POST') => {
        return request(_baseUrl + url, {
            method,
            headers: { Accept: 'application/json', Authorization: `Bearer ${_token}` },
            body: data,
        });
    };

    const postEncrypt = async (data: any) => {
        const res = await send('get.deal.encrypt', data);
        return res;
    };

    const createDeal = async (data: any) => {
        return await send('add.deal', data);
    };

    const updateDeal = async (data: any) => {
        return await send('update.deal', data);
    };

    const finishDeal = async (data: any) => {
        return await send('finish', data);
    };

    const codeSent = async (data: any) => {
        return await send('code.sent', data);
    };

    const codeCheck = async (data: any) => {
        return await send('code.check', data);
    };

    const getDealEncrypt = async (data: any) => {
        return await send('get.deal.encrypt', data);
    };

    return { createDeal, updateDeal, postEncrypt, finishDeal, codeSent, codeCheck, getDealEncrypt };
};

export default useSend;
