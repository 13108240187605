import { FC } from 'react';

import './style.css';

import { buttonScrollTypes } from './types';

const ButtonScroll: FC<buttonScrollTypes> = ({ text = 'Оформить кредит', clasz = '' }) => {
    const handleClick = () => {
        const url = new URL(document.URL);
        let searchParams = '?';
        url.searchParams.forEach(function (val, key) {
            searchParams = `${searchParams}${key}=${val}&`;
        });

        const tab = window.open(`/anketa/${searchParams.slice(0, -1)}`, '_blank');

        setTimeout(() => {
            window.location.href = '/vitrina-b';
            tab?.focus();
        }, 100);
    };

    return (
        <button className={`button-scroll ${clasz}`} onClick={handleClick}>
            <div className="button-scroll__span">{text}</div>
        </button>
    );
};

export default ButtonScroll;
