import { FC, useContext, useEffect, useState } from 'react';
import { RenderSuggestion } from 'react-autosuggest';
import HighlightWords from 'react-highlight-words';

import { setCity, setCityCreditKladr, setRegionCredit } from '../../../../actions/formQuest';
import { useDadataAddress } from '../../../../hooks/dadataApiHooks';
import { FormQuestContext } from '../../../../reducer/reducer';
import useStep1 from '../../../../services/formQuestServise/Step1Servise';
import { getBadgeContent } from '../../../../utils';
import { Badge } from '../../badge/Badge';
import { SuggestInput } from '../../suggestInput/SuggestInput';

interface Props {
    inputRef: any;
}

/** параметры дадаты */
const locations = [{ country_iso_code: 'RU' }];
const params = { count: 5, locations, from_bound: { value: 'city' }, to_bound: { value: 'city' } };

/** маппер для получения suggestionValue */
const getSuggestionValue = (suggestion: any) => suggestion.value;

/** рендер подсказки в списке */
const renderSuggestion: RenderSuggestion<any> = (suggestion, params) => {
    return (
        <div>
            <HighlightWords
                searchWords={[
                    new RegExp(
                        params.query.replace(/([!@#$%^&*()+=\\[\]\\';,./{}|":<>?~_-])/g, '\\$1'),
                        'g',
                    ),
                ]}
                textToHighlight={suggestion.value}
            />
        </div>
    );
};

export const CitySuggest: FC<Props> = ({ inputRef }) => {
    const [focused, setFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const { data, trigger } = useDadataAddress(params);

    const { state, dispatch } = useContext(FormQuestContext);
    const { city, cityCreditKladr, regionCredit } = state;

    const { maskCity, validationCity } = useStep1();

    const handleChange = (e: any, { newValue }: any) => {
        setCity(dispatch, city, maskCity(newValue), false, false);
        setCityCreditKladr(dispatch, cityCreditKladr, '', false, false);
        setRegionCredit(dispatch, regionCredit, '', false, false);
    };

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        trigger(value);
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (e: any, data: any) => {
        const cityValue = data.suggestion.data.city;
        const cityCreditKladrValue =
            data.suggestion.data.city_kladr_id ||
            data.suggestion.data.area_kladr_id ||
            data.suggestion.data.region_kladr_id ||
            '';
        const regionCredit = data.suggestion.data.region || '';
        const isValid = validationCity(city.errorMessages, cityValue, true);

        setCity(dispatch, city, maskCity(cityValue), isValid, true);
        setCityCreditKladr(dispatch, cityCreditKladr, cityCreditKladrValue, true, true);
        setRegionCredit(dispatch, regionCredit, regionCredit, true, true);
    };

    useEffect(() => {
        if (data) {
            setSuggestions(data.suggestions);
        }
    }, [data]);

    const inputProps = {
        placeholder: 'Город',
        value: city.value,
        onChange: handleChange,
        onFocus: () => {
            setFocused(true);

            if (!focused && inputRef.current) {
                const r = inputRef.current;

                setTimeout(() => {
                    r.setSelectionRange(city.value.length, city.value.length);
                }, 10);
            }
        },
        onBlur: () => {
            setFocused(false);
        },
        ref: inputRef,
    };

    const badgeContent = getBadgeContent(city.points);

    const inputBadge = city.isValid ? (
        city.isValid === true ? null : (
            <Badge badgeContent={badgeContent} />
        )
    ) : focused ? (
        <Badge badgeContent={badgeContent} />
    ) : null;

    return (
        <SuggestInput
            label="Город получения кредита"
            inputBadge={inputBadge}
            inputProps={inputProps}
            isValid={city.isValid}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
        />
    );
};
