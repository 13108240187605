import { MailingPolicy } from '../layout/policy/MailingPolicy';

import Wrapper from './../layout/wrapper/Wrapper';

const MailingPolicyPage = () => {
    return (
        <Wrapper>
            <div style={{ maxWidth: 1140, margin: 'auto', padding: '20px 0' }}>
                <MailingPolicy />
            </div>
        </Wrapper>
    );
};

export default MailingPolicyPage;
