import qs from 'query-string';

const parsed = qs.parse(window.location.search);

const getField = (
    { value, isValid, errorMessages, points, isDadata } = {
        value: '',
        isValid: true,
        errorMessages: {
            default: '',
        },
        points: 0,
        isDadata: true,
    },
) => ({
    value,
    isValid,
    errorMessages,
    points,
    isDadata,
});

export const formQuestInitStore = {
    checkbox1: {
        value: 'true',
        isValid: true,
        errorMessages: {
            default: 'Примите оба согласие для отправки заявки и получения кредита',
        },
        points: 0,
    },
    checkbox2: {
        value: parsed['utm_source'] ? 'true' : '',
        isValid: parsed['utm_source'] ? true : false,
        errorMessages: {
            default: 'Примите оба согласие для отправки заявки и получения кредита',
        },
        points: 0,
    },
    fio: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            pattern: 'ФИО содержит недопустимые символы',
            name: 'Укажите ваше имя',
            patronymic: 'Укажите ваше отчество',
            max: 'ФИО должно содержать до 4 слов',
        },
        points: 10,
    },
    sum: {
        value: '',
        isValid: false,
        points: 10,
    },
    dateBirth: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            less: 'Минимальный возраст - 18 лет',
            more: 'Максимальный возраст - 80 лет',
            notDate: 'Укажите вашу настоящую дату рождения',
            len: 'Дата рождения состоит из 8 цифр',
        },
        points: 10,
    },
    phone: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            noPhone: 'Укажите настоящий номер телефона',
            len: 'Номер телефона состоит из 10 цифр',
        },
        points: 25,
    },
    email: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            format: 'Неверный формат электронной почты',
        },
        points: 10,
    },
    city: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
        },
        isDadata: false,
        points: 5,
    },
    cityCreditKladr: {
        value: '',
        isValid: false,
        errorMessages: {
            default: '',
        },
        isDadata: false,
        points: 0,
    },
    regionCredit: {
        value: '',
        isValid: false,
        errorMessages: {
            default: '',
        },
        isDadata: false,
        points: 0,
    },
    // Step2
    income: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    employment: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    methodConfirmingIncome: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    creditHistory: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    education: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    own: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 3,
    },
    startWorkLastPlaceMonth: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо заполнить это поле',
        },
        points: 3,
    },
    startWorkLastPlaceYear: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо заполнить это поле',
        },
        points: 0,
    },

    // Step3
    organization: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
        },
        isDadata: false,
        points: 1,
    },
    workInn: getField(),
    workHead: getField(),
    workCity: getField(),
    workStreet: getField(),
    workHouse: getField(),
    workOpf: getField(),
    workRegionKladr: getField(),
    workRegion: getField(),
    okvedMain: getField(),
    workCityKladr: getField(),
    workStreetKladr: getField(),
    workHouseKladr: getField(),
    workAddress: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
            city: 'Укажите населённый пункт',
            house: 'Укажите дом',
        },
        isDadata: false,
        points: 1,
    },
    workPhone: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            noPhone: 'Укажите настоящий номер телефона',
            len: 'Номер телефона состоит из 10 цифр',
        },
        points: 1,
    },
    positionWork: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 1,
    },
    totalWorkExperience: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 1,
    },
    familyStatus: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 1,
    },
    amountChildren: {
        value: '0',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
        },
        points: 0,
    },
    loansPerMonth: {
        value: '0',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
        },
        points: 0,
    },
    placeResidence: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
            city: 'Укажите населённый пункт',
            street: 'Укажите улицу',
            house: 'Укажите дом',
            manual: 'Выберите адрес из списка до номера дома включительно',
        },
        isDadata: false,
        points: 1,
    },
    registrationСode: getField(),
    registrationRegion: getField(),
    registrationCity: getField(),
    registrationStreet: getField(),
    registrationHome: getField(),
    registrationFlat: getField(),
    registrationHomeKladr: getField(),

    // Step4
    passportNumberSeries: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            min: 'Серия и номер паспорта состоят из 10 цифр',
        },
        points: 1,
    },
    dateOfIssue: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            min: 'Дата выдачи состоит из 8 цифр',
            noDate: 'Укажите вашу настоящую дату выдачи паспорта',
            noValid: 'Неверно указана дата выдачи паспорта',
            moreCurrent: 'Дата выдачи не может быть позднее сегодняшней',
            series: 'Дата выдачи не соответствует серии паспорта',
        },
        points: 1,
    },
    unitСode: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            min: 'Дата выдачи состоит из 6 цифр',
            choice: 'Необходимо выбрать из списка',
        },
        points: 1,
    },
    issuedBy: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
        },
        points: 1,
    },
    placeOfBirth: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
        },
        isDadata: false,
        points: 1,
    },
    contactPhone: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            len: 'Номер телефона состоит из 10 цифр',
            duplicate: 'Укажите номер, который еще не был указан в анкете',
        },
        points: 0,
    },
    contactPerson: {
        value: '226',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 0,
    },
    contactPersonFio: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            pattern: 'ФИО содержит недопустимые символы',
            name: 'Укажите имя',
            patronymic: 'Укажите отчество',
            max: 'ФИО должно содержать до 4 слов',
        },
        points: 0,
    },
    contactPersonFirstname: {
        value: '',
        isValid: true,
        errorMessages: {
            default: '',
        },
        points: 0,
    },
    contactPersonSurname: {
        value: '',
        isValid: true,
        errorMessages: {
            default: '',
        },
        points: 0,
    },
    contactPersonPatronymic: {
        value: '',
        isValid: true,
        errorMessages: {
            default: '',
        },
        points: 0,
    },
};

export const step1Fields = {
    fio: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            pattern: 'ФИО содержит недопустимые символы',
            name: 'Укажите ваше имя',
            patronymic: 'Укажите ваше отчество',
            max: 'ФИО должно содержать до 4 слов',
        },
        points: 10,
    },
    sum: {
        value: '',
        isValid: false,
        points: 10,
    },
    dateBirth: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            less: 'Минимальный возраст - 18 лет',
            more: 'Максимальный возраст - 80 лет',
            notDate: 'Укажите вашу настоящую дату рождения',
            len: 'Дата рождения состоит из 8 цифр',
        },
        points: 10,
    },
    phone: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            noPhone: 'Укажите настоящий номер телефона',
            len: 'Номер телефона состоит из 10 цифр',
        },
        points: 25,
    },
    email: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            format: 'Неверный формат электронной почты',
        },
        points: 10,
    },
    city: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
        },
        isDadata: false,
        points: 5,
    },
    cityCreditKladr: {
        value: '',
        isValid: false,
        errorMessages: {
            default: '',
        },
        isDadata: false,
        points: 0,
    },
    regionCredit: {
        value: '',
        isValid: false,
        errorMessages: {
            default: '',
        },
        isDadata: false,
        points: 0,
    },

    checkbox2: {
        value: parsed['utm_source'] ? 'true' : '',
        isValid: parsed['utm_source'] ? true : false,
        errorMessages: {
            default: 'Примите оба согласие для отправки заявки и получения кредита',
        },
        points: 0,
    },
};

export const step2Fields = {
    income: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    employment: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    methodConfirmingIncome: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    creditHistory: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    education: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 2,
    },
    own: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 3,
    },
    startWorkLastPlaceMonth: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо заполнить это поле',
        },
        points: 3,
    },
    startWorkLastPlaceYear: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо заполнить это поле',
        },
        points: 0,
    },
};

export const step3Fields = {
    organization: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
        },
        points: 1,
    },
    workInn: getField(),
    workHead: getField(),
    workCity: getField(),
    workStreet: getField(),
    workHouse: getField(),
    workOpf: getField(),
    workRegionKladr: getField(),
    workRegion: getField(),
    okvedMain: getField(),
    workCityKladr: getField(),
    workStreetKladr: getField(),
    workHouseKladr: getField(),
    workAddress: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
            city: 'Укажите населённый пункт',
            house: 'Укажите дом',
        },
        isDadata: false,
        points: 1,
    },
    workPhone: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            noPhone: 'Укажите настоящий номер телефона',
            len: 'Номер телефона состоит из 10 цифр',
        },
        points: 1,
    },
    positionWork: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 1,
    },
    totalWorkExperience: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 1,
    },
    familyStatus: {
        value: 'none',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 1,
    },
    amountChildren: {
        value: '0',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
        },
        points: 0,
    },
    loansPerMonth: {
        value: '0',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
        },
        points: 0,
    },
    placeResidence: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
            city: 'Укажите населённый пункт',
            street: 'Укажите улицу',
            house: 'Укажите дом',
            manual: 'Выберите адрес из списка до номера дома включительно',
        },
        isDadata: false,
        points: 1,
    },
    registrationСode: getField(),
    registrationRegion: getField(),
    registrationCity: getField(),
    registrationStreet: getField(),
    registrationHome: getField(),
    registrationFlat: getField(),
    registrationHomeKladr: getField(),
};

export const step4Fields = {
    passportNumberSeries: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            min: 'Серия и номер паспорта состоят из 10 цифр',
        },
        points: 1,
    },
    dateOfIssue: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            min: 'Дата выдачи состоит из 8 цифр',
            noDate: 'Укажите вашу настоящую дату выдачи паспорта',
            noValid: 'Неверно указана дата выдачи паспорта',
        },
        points: 1,
    },
    unitСode: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            min: 'Дата выдачи состоит из 6 цифр',
            choice: 'Необходимо выбрать из списка',
        },
        points: 1,
    },
    issuedBy: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
        },
        points: 1,
    },
    placeOfBirth: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            choice: 'Необходимо выбрать из списка',
        },
        isDadata: false,
        points: 1,
    },
    contactPhone: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            len: 'Номер телефона состоит из 10 цифр',
            duplicate: 'Укажите номер, который еще не был указан в анкете',
        },
        points: 0,
    },
    contactPerson: {
        value: '226',
        isValid: false,
        errorMessages: {
            choice: 'Необходимо выбрать из списка',
        },
        points: 0,
    },
    contactPersonFio: {
        value: '',
        isValid: false,
        errorMessages: {
            default: 'Необходимо заполнить это поле',
            pattern: 'ФИО содержит недопустимые символы',
            name: 'Укажите имя',
            patronymic: 'Укажите отчество',
            max: 'ФИО должно содержать до 4 слов',
        },
        points: 0,
    },
    contactPersonFirstname: {
        value: '',
        isValid: true,
        errorMessages: {
            default: '',
        },
        points: 0,
    },
    contactPersonSurname: {
        value: '',
        isValid: true,
        errorMessages: {
            default: '',
        },
        points: 0,
    },
    contactPersonPatronymic: {
        value: '',
        isValid: true,
        errorMessages: {
            default: '',
        },
        points: 0,
    },
};
