const SUBSCRIBE = (baseUrl: string) => `
<style>
#popup_agreement {
    padding: 12px;
    text-align: justify
}

#popup_agreement span {
    font-size: inherit;
    line-height: inherit
}

#popup_agreement span.left-margin {
    margin-left: 20px
}

#popup_agreement p span {
    margin-top: auto!important;
    font-weight: 400!important
}
#popup_agreement span.right-align, #popup_agreement2 span.right-align {
    text-align: right!important;
    width: 100%!important;
}
#popup_agreement span.bold, #popup_agreement2 span.bold {
    font-weight: bold!important;
}
.paragraph, .paragraph__main {
    margin: 10px 0!important;
}
.paragraph__main {
    font-weight: bold!important;
}
.paragraph, .paragraph__main {
    margin: 10px 0!important;
}
.ul__line {
    list-style: none!important;
    padding-inline-start: 40px!important;
}
#popup_agreement table, #popup_agreement2 table {
    border: 1px solid black!important;
    border-collapse: collapse!important;
}
#popup_agreement th, #popup_agreement2 th {
    text-align: center!important;
}
#popup_agreement th, #popup_agreement td, #popup_agreement2 th, #popup_agreement2 td {
    width: 50%!important;
    vertical-align: top!important;
    padding: 5px!important;
}
</style>

<div id="popup_agreement" class="popup_agreement" style="display: block;">

    <span class="right-align" style="display: block;">Утверждено <span class="bold">«РЕШБАНК»</span></span>
    <span class="right-align" style="display: block;">Генеральный директор ООО «КРЕДИТМАРТ»</span>
    <span class="right-align" style="display: block;">Баранцова М. Е.</span>
    <span class="right-align" content="noindex" style="display: block;">ИП Крымский П.В.</span>
    <span class="right-align" style="display: block;">Политика обработки персональных данных</span>
    <span class="right-align" style="display: block;">от 15.01.2023 г.</span>
    <div class="paragraph__main">1. Общие положения</div>

    <div class="paragraph">
        1.1. Политика обработки персональных данных (далее - Политика) разработана в соответствии с федеральными законами и подзаконными актами Российской Федерации, определяющими случаи и особенности
        обработки персональных данных и обеспечения безопасности и конфиденциальности такой информации (далее - Законодательство о персональных данных).
    </div>
    <div class="paragraph">
        1.2. Политика разработана в целях реализации требований законодательства в области обработки и обеспечения безопасности персональных данных. Политика направлена на обеспечение защиты прав и свобод человека и гражданина при обработке его
        персональных данных и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ООО «Кредитмарт» ОГРН 1167746596896 от 24 июня 2016 г. и <span content="noindex">ИП Крымский П.В. ОГРНИП 322774600321768 от 25.05.2022г.</span> (далее – Оператор).
    </div>
    <div class="paragraph">1.3. Политика устанавливает:</div>
    <ul class="ul__line">
        <li>цели обработки персональных данных;</li>
        <li>классификацию персональных данных и Субъектов персональных данных;</li>
        <li>общие принципы обработки персональных данных;</li>
        <li>основные подходы к системе управления процессом обработки персональных данных.</li>
    </ul>
    <div class="paragraph">
        1.4. Положения настоящей Политики являются основой для организации работы по обработке персональных данных в <span class="bold">Оператор</span>, в том числе, для разработки внутренних нормативных документов 2-го и 3-го уровня
        (регламентов, методик, технологических схем и пр.), регламентирующих процесс обработки персональных данных в <span class="bold">Оператор</span>
    </div>

    <div class="paragraph__main">2. Цели обработки персональных данных.</div>
    <div class="paragraph">2.1. <span class="bold">Оператор</span> осуществляет обработку персональных данных в целях:</div>
    <ul class="ul__line">
        <li>заключения с Субъектом персональных данных любых договоров и их дальнейшего исполнения;</li>
        <li>предоставления Субъекту персональных данных информации об оказываемых <span class="bold">Оператор</span> услугах, о разработке <span class="bold">Оператор</span> новых продуктов и услуг;</li>
        <li></li>
        <li>информирования Клиента о предложениях по продуктам и услугам <span class="bold">Оператор</span>;</li>
        <li>формирования статистической отчетности, в том числе для предоставления третьим лицам.</li>
    </ul>

    <div class="paragraph__main">3. Цели и правовые основания обработки данных:</div>
    <div class="paragraph">С учетом применимого законодательства цель Обработки Персональных данных и правовые основания осуществления такой Обработки представлены ниже:</div>
    <table cellspacing="0" cellpadding="0">
        <tbody>
            <tr>
                <th><span class="bold">Действие, относящееся к обработке данных</span></th>
                <th><span class="bold">Правовое основание обработки данных</span></th>
            </tr>

            <tr>
                <td>
                    Предоставление Веб-сайтов, Приложения, продукции и оказание услуг: предоставление наших Веб-сайтов, Приложения, продукции или оказание услуг; предоставление рекламной продукции по запросу; информационное взаимодействие с
                    вами в отношении таких Веб-сайтов, Приложения, продукции или услуг.
                </td>
                <td>
                    Обработка данных необходима в связи с любым договором, который вы заключили с нами, или в целях принятия мер до заключения договора с нами; у нас есть законное право на осуществление Обработки данных с целью
                    предоставления наших Веб-сайтов, Приложения, продукции или оказания услуг (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным правом); или мы получили ваше
                    предварительное согласие на Обработку данных (данное правовое основание действует только в отношении полностью добровольной Обработки данных, оно не применяется в отношении Обработки данных, которая на любом основании
                    является необходимой или обязательной).
                </td>
            </tr>

            <tr>
                <td>
                    Проверки соблюдения требований: исполнение наших обязательств по соблюдению нормативных требований; проверки по процедуре идентификации клиента; подтверждение и идентификация вашей личности; привлечение бюро кредитных
                    историй; проверка по санкционным спискам государственных и (или) правоохранительных органов, а также проверка наличия иных правовых ограничений.
                </td>
                <td>
                    Обработка данных необходима в целях исполнения юридического обязательства; Обработка данных необходима в связи с любым договором, который вы заключили с нами, или в целях принятия мер до заключения договора с нами; у нас
                    есть законное право на осуществление Обработки данных с целью исполнения наших нормативных и юридических обязательств (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким
                    законным правом); или мы получили ваше предварительное согласие на Обработку данных (данное правовое основание действует только в отношении полностью добровольной Обработки данных, оно не применяется в отношении
                    Обработки данных, которая на любом основании является необходимой или обязательной).
                </td>
            </tr>

            <tr>
                <td>
                    Ведение бизнеса: эксплуатация наших Веб-сайтов, Приложения, продукции и услуг и управление ими; предоставление вам контента; отображение рекламы и другой информации для вас; информационное и иное взаимодействие с вами
                    через наши Веб-сайты, Приложение, посредством предоставления нашей продукции или оказания наших услуг; уведомление вас об изменениях в наших Веб-сайтах, Приложении, продукции или услугах.
                </td>
                <td>
                    Обработка данных необходима в связи с любым договором, который вы заключили с нами, или в целях принятия мер до заключения договора с нами; у нас есть законное право на осуществление Обработки данных с целью
                    предоставления вам наших Веб-сайтов, Приложения, продукции или оказания наших услуг (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным правом); или мы получили
                    ваше предварительное согласие на Обработку данных (данное правовое основание действует только в отношении полностью добровольной Обработки данных, оно не применяется в отношении Обработки данных, которая на любом
                    основании является необходимой или обязательной).
                </td>
            </tr>

            <tr>
                <td>
                    Информационное взаимодействие и маркетинг: информационное взаимодействие с вами с помощью любых средств (в том числе по электронной почте, телефону, с помощью текстовых сообщений, в социальных сетях, по почте или лично)
                    для предоставления новостных материалов и иной информации, которая может вас заинтересовать, с непременным учетом необходимости получения вашего предварительного согласия на подписку на информационную рассылку в
                    соответствии с требованиями применимого законодательства; сохранение и обновление вашей контактной информации (в соответствующих случаях); получение вашего предварительного согласия на подписку на информационную рассылку
                    (при необходимости).
                </td>
                <td>
                    Обработка данных необходима в связи с любым договором, который вы заключили с нами, или в целях принятия мер до заключения договора с нами; у нас есть законное право на осуществление Обработки данных с целью обращения к
                    вам с учетом необходимости постоянного соблюдения применимого законодательства (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным правом); или мы получили ваше
                    предварительное согласие на Обработку данных (данное правовое основание действует только в отношении полностью добровольной Обработки данных, оно не применяется в отношении Обработки данных, которая на любом основании
                    является необходимой или обязательной).
                </td>
            </tr>

            <tr>
                <td>
                    Управление ИТ-системами: управление нашими коммуникационными, ИТ-системами и системами безопасности, а также их эксплуатация; проведение аудитов (включая аудиты безопасности) и осуществление контроля в отношении таких
                    систем.
                </td>
                <td>
                    Обработка данных необходима в целях исполнения юридического обязательства; или у нас есть законное право на осуществление Обработки данных с целью управления нашими коммуникационными и ИТ-системами, а также их
                    технического обслуживания (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным правом).
                </td>
            </tr>

            <tr>
                <td>
                    Охрана труда и техника безопасности: анализ показателей охраны труда и техники безопасности и их учет; предоставление безопасной и защищенной среды на наших объектах; исполнение соответствующих юридических обязательств.
                </td>
                <td>
                    Обработка данных необходима в целях исполнения юридического обязательства; у нас есть законное право на осуществление Обработки данных с целью обеспечения безопасной среды на наших объектах (в случаях, когда ваши
                    интересы, основные права или свободы не имеют преимущественной силы над таким законным правом); или обработка данных необходима для защиты жизненно важных интересов какого-либо физического лица.
                </td>
            </tr>

            <tr>
                <td>Управление финансами: продажи; финансы; проведение корпоративного аудита; управление сетью поставщиков</td>
                <td>
                    У нас есть законное право на осуществление Обработки данных с целью управления финансами и решения финансовых вопросов в рамках нашей коммерческой деятельности (в случаях, когда ваши интересы, основные права или свободы
                    не имеют преимущественной силы над таким законным правом); или мы получили ваше предварительное согласие на Обработку данных (данное правовое основание действует только в отношении полностью добровольной Обработки
                    данных, оно не применяется в отношении Обработки данных, которая на любом основании является необходимой или обязательной).
                </td>
            </tr>

            <tr>
                <td>Опросы: взаимодействие с вами с целью получения вашего мнения о наших Веб-сайтах, Приложении, продукции или услугах.</td>
                <td>
                    У нас есть законное право на осуществление Обработки данных с целью проведения опросов, составления отчетов об уровне удовлетворенности клиентов и исследования рынка (в случаях, когда ваши интересы, основные права или
                    свободы не имеют преимущественной силы над таким законным правом); или мы получили ваше предварительное согласие на Обработку данных (данное правовое основание действует только в отношении полностью добровольной
                    Обработки данных, оно не применяется в отношении Обработки данных, которая на любом основании является необходимой или обязательной).
                </td>
            </tr>

            <tr>
                <td>Безопасность: обеспечение физической безопасности наших объектов (включая записи посещений наших объектов); записи видеонаблюдения; электронная безопасность (включая записи входов в систему и данные доступа).</td>
                <td>
                    Обработка данных необходима в целях исполнения юридического обязательства; или у нас есть законное право на осуществление Обработки данных с целью обеспечения физической и электронной безопасности нашего бизнеса и
                    объектов (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным правом).
                </td>
            </tr>

            <tr>
                <td>Проведение расследований: выявление, расследование и предотвращение нарушений политики и уголовно наказуемых преступлений в соответствии с применимым законодательством.</td>
                <td>
                    Обработка данных необходима в целях исполнения юридического обязательства; или у нас есть законное право на осуществление Обработки данных с целью выявления нарушений наших политик и применимого законодательства, а также
                    защиты от таких нарушений (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным правом).
                </td>
            </tr>

            <tr>
                <td>Соблюдение законов и норм: исполнение наших юридических и нормативных обязательств в соответствии с применимым законодательством.</td>
                <td>Обработка данных необходима в целях исполнения юридического обязательства.</td>
            </tr>

            <tr>
                <td>
                    Улучшение наших Веб-сайтов, Приложения, продукции и услуг: выявление проблем в работе наших Веб-сайтов, Приложения, проблем с нашей продукцией или услугами; планирование улучшений наших Веб-сайтов, Приложения, продукции
                    или услуг; создание новых Веб-сайтов, Приложения, продукции или услуг.
                </td>
                <td>
                    У нас есть законное право на осуществление Обработки данных с целью улучшения наших Веб-сайтов, Приложения, продукции или услуг (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы
                    над таким законным правом); или мы получили ваше предварительное согласие на Обработку данных (данное правовое основание действует только в отношении полностью добровольной Обработки данных, оно не применяется в
                    отношении Обработки данных, которая на любом основании является необходимой или обязательной).
                </td>
            </tr>

            <tr>
                <td>Предотвращение случаев мошенничества: выявление, предотвращение и расследование случаев мошенничества.</td>
                <td>
                    Обработка данных необходима в целях исполнения юридического обязательства (в частности, в отношении применимого трудового законодательства); или у нас есть законное право на осуществление Обработки данных с целью
                    выявления случаев мошенничества и защиты от них (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным правом).
                </td>
            </tr>

            <tr>
                <td>
                    Установление, исполнение и защита законных требований: управление законными требованиями; установление фактов и требований, включая сбор, рассмотрение и предъявление документов, фактов, доказательств и письменных
                    заявлений свидетелей; осуществление законных прав, исполнение законных требований, защита законных прав и требований, в том числе в рамках официальных судебных разбирательств.
                </td>
                <td>
                    Обработка данных необходима в целях исполнения юридического обязательства; у нас есть законное право на осуществление Обработки данных с целью установления, осуществления или защиты наших законных прав (в случаях, когда
                    ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным правом); или Обработка необходима в целях установления, исполнения или защиты законных требований.
                </td>
            </tr>

            <tr>
                <td>
                    Подбор персонала и рассмотрение заявлений о приеме на работу: подбор персонала; реклама вакантных должностей; проведение собеседований; анализ соответствия предполагаемой должности; записи решений в отношении найма;
                    данные предложений; данные о принятии предложений.
                </td>
                <td>
                    Обработка данных необходима в целях исполнения юридического обязательства (в частности, в отношении применимого трудового законодательства); у нас есть законное право на осуществление Обработки данных с целью подбора
                    персонала и рассмотрения заявлений о приеме на работу (в случаях, когда ваши интересы, основные права или свободы не имеют преимущественной силы над таким законным правом); или мы получили ваше предварительное согласие
                    на Обработку данных (данное правовое основание действует только в отношении полностью добровольной Обработки данных, оно не применяется в отношении Обработки данных, которая на любом основании является необходимой или
                    обязательной).
                </td>
            </tr>
        </tbody>
    </table>

    <div class="paragraph__main">4. Общие принципы обработки персональных данных</div>

    <div class="paragraph">4.1. <span class="bold">Оператор</span> осуществляет обработку персональных данных на основе общих принципов:</div>
    <ul class="ul__line">
        <li>законности заранее определенных конкретных целей и способов обработки персональных данных;</li>
        <li>обеспечения надлежащей защиты персональных данных;</li>
        <li>соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных;</li>
        <li>соответствия объема, характера и способов обработки персональных данных целям обработки персональных данных;</li>
        <li>достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;</li>
        <li>недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</li>
        <li>хранения персональных данных в форме, позволяющей определить Субъекта персональных данных, не дольше, чем этого требуют цели их обработки;</li>
        <li>
            уничтожения или обезличивания персональных данных по достижении целей их обработки, если срок хранения персональных данных не установлен законодательством Российской Федерации, договором, стороной которого, выгодоприобретателем
            или поручителем по которому является Субъект персональных данных;
        </li>
        <li>обеспечения конфиденциальности и безопасности обрабатываемых персональных данных.</li>
    </ul>
    <div class="paragraph">4.2. В рамках обработки персональных данных для Субъекта персональных данных и <span class="bold">Оператор</span> определены следующие права.</div>

    <div class="paragraph">4.2.1. Субъект персональных данных имеет право:</div>
    <ul class="ul__line">
        <li>получать информацию, касающуюся обработки его персональных данных, в порядке, форме и сроки, установленные Законодательством о персональных данных;</li>
        <li>
            требовать уточнения своих персональных данных, их Блокирования или Уничтожения в случае, если персональные данные являются неполными, устаревшими, недостоверными, незаконно полученными, не являются необходимыми для заявленной
            цели обработки или используются в целях, не заявленных ранее при предоставлении Субъектом персональных данных согласия на обработку персональных данных;
        </li>
        <li>принимать предусмотренные законом меры по защите своих прав;</li>
        <li>отозвать свое согласие на обработку персональных данных.</li>
    </ul>
    <div class="paragraph">4.2.2. <span class="bold">Оператор</span> имеет право:</div>
    <ul class="ul__line">
        <li>обрабатывать персональные данные Субъекта персональных данных в соответствии с заявленной целью;</li>
        <li>
            требовать от Субъекта персональных данных предоставления достоверных персональных данных, необходимых для исполнения договора, оказания услуги, идентификации Субъекта персональных данных, а также в иных случаях, предусмотренных
            Законодательством о персональных данных;
        </li>
        <li>
            ограничить доступ Субъекта персональных данных к его персональным данным в случае, если Обработка персональных данных осуществляется в соответствии с законодательством о противодействии легализации (отмыванию) доходов,
            полученных преступным путем, и финансированию терроризма, доступ Субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц, а также в иных случаях, предусмотренных законодательством
            Российской Федерации;
        </li>
        <li>обрабатывать общедоступные персональные данные физических лиц;</li>
        <li>осуществлять обработку персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с законодательством Российской Федерации;</li>
        <li>поручить обработку персональных данных другому лицу с согласия Субъекта персональных данных.</li>
    </ul>

    <div class="paragraph__main">5. Перечень обрабатываемых персональных данных.</div>

    <div class="paragraph">5.1. <span class="bold">Оператор</span> осуществляет обработку следующих персональных данных:</div>
    <ul class="ul__line">
        <div class="paragraph">
            <span class="bold">Личные данные:</span> фамилия; имя; отчество; год рождения; месяц рождения; дата рождения; идентификаторы или ссылки на персональные страницы на сайтах социальных сетей или иных коммуникационных сервисов;
            фото- и видео- материалов; содержащих личные изображения; национальная принадлежность; состояние здоровья; сведения об интересах;
        </div>
        <div class="paragraph"><span class="bold">Идентифицирующие сведения:</span> реквизиты документа, удостоверяющего личность; ИНН; СНИЛС;</div>
        <div class="paragraph"><span class="bold">Контактные данные:</span> номера контактных телефонов; адрес электронной почты;</div>
        <div class="paragraph">
            <span class="bold">Демографические данные:</span> место рождения; адрес; семейное положение места работы и занимаемой должности; пол; адреса регистрации; адреса фактического проживания; информация о количестве детей; сведения о
            трудовом стаже и графике работы;
        </div>
        <div class="paragraph"><span class="bold">Финансовые сведения:</span> сведения о кредитной истории; социальное положение; имущественное положение; образование; профессия; доходы;</div>
        <div class="paragraph">
            <span class="bold">Данные, относящиеся к нашим Веб-сайтам и Приложениям:</span> пользовательские данные (IP- адреса; cookie; сведений о местоположении; типа и версии ОС; типа и версии Браузера; типа устройства и разрешения его
            экрана; источника откуда пришел на сайт пользователь, с какого сайта или по какой рекламе; языка ОС и Браузера )
        </div>
    </ul>

    <div class="paragraph__main">6. Организация системы управления процессом обработки персональных данных.</div>
    <div class="paragraph">
        6.1. Обработка персональных данных Субъекта осуществляется с его согласия на обработку персональных данных, а также без такового, если Обработка персональных данных необходима для исполнения договора, стороной которого либо
        выгодоприобретателем или поручителем по которому является Субъект персональных данных, а также для заключения договора по инициативе Субъекта персональных данных или договора, по которому Субъект персональных данных будет являться
        выгодоприобретателем или поручителем или в иных случаях, предусмотренных Законодательством о персональных данных.
    </div>
    <div class="paragraph">
        6.2. <span class="bold">Оператор</span> вправе поручить обработку персональных данных другому лицу с согласия Субъекта персональных данных, если иное не предусмотрено федеральным законом. Такая Обработка персональных данных
        осуществляется только на основании договора, заключенного между <span class="bold">Оператор</span> и третьим лицом, в котором должны быть определены:
    </div>
    <ul class="ul__line">
        <li>перечень действий (операций) с персональными данными, которые будут совершаться третьим лицом, осуществляющим обработку персональных данных;</li>
        <li>цели обработки персональных данных;</li>
        <li>обязанности третьего лица соблюдать конфиденциальность персональных данных и обеспечивать их безопасность при обработке, а также требования к защите обрабатываемых персональных данных.</li>
    </ul>

    <div class="paragraph">6.3. <span class="bold">Оператор</span> осуществляет передачу персональных данных государственным органам в рамках их полномочий в соответствии с законодательством Российской Федерации.</div>
    <div class="paragraph">6.4. <span class="bold">Оператор</span> несет ответственность перед Субъектом персональных данных за действия лиц, которым поручает обработку персональных данных Субъекта персональных данных.</div>
    <div class="paragraph">
        6.5. Обработка персональных данных прекращается при достижении целей такой обработки, а также по истечении срока, предусмотренного законом, договором, или согласием Субъекта персональных данных на обработку его персональных данных.
        При отзыве Субъектом персональных данных согласия на обработку его персональных данных Обработка осуществляется только в пределах, необходимых для исполнения заключенных с ним договоров и в целях, предусмотренных законодательством
        Российской Федерации.
    </div>
    <div class="paragraph">
        6.6. Обработка персональных данных осуществляется с соблюдением конфиденциальности, под которой понимается обязанность не раскрывать третьим лицам и не распространять персональные данные без согласия Субъекта персональных данных,
        если иное не предусмотрено законодательством Российской Федерации.
    </div>
    <div class="paragraph">
        6.7. <span class="bold">Оператор</span> обеспечивает конфиденциальность персональных данных Субъекта персональных данных со своей стороны, со стороны своих аффилированных лиц, а также обеспечивает использование персональных данных
        вышеуказанными лицами исключительно в целях, соответствующих закону, договору или иному соглашению, заключенному с Субъектом персональных данных.
    </div>
    <div class="paragraph">
        6.8. Обеспечение безопасности обрабатываемых персональных данных осуществляется <span class="bold">Оператор</span> в рамках единой комплексной системы организационно-технических и правовых мероприятий по защите информации,
        составляющей коммерческую тайну, с учетом требований Законодательства о персональных данных, принятых в соответствии с ним нормативных правовых актов. Система информационной безопасности <span class="bold">Оператор</span> непрерывно
        развивается и совершенствуется на базе требований международных и национальных стандартов информационной безопасности, а также лучших мировых практик.
    </div>

    <div class="paragraph__main">7. Меры, принимаемые для защиты персональных данных.</div>
    <div class="paragraph">
        7.1. <span class="bold">Оператор</span> самостоятельно определяет состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Федеральным законом «О персональных данных» и принятыми в
        соответствии с ним нормативными правовыми актами, если иное не предусмотрено Федеральным законом «О персональных данных» или другими федеральными законами.
    </div>
    <div class="paragraph">7.2. <span class="bold">Оператор</span> принимает следующие меры для защиты персональных данных:</div>
    <ul class="ul__line">
        <li>
            осуществление внутреннего контроля и (или) аудита соответствия обработки персональных данных ФЗ "О персональных данных" и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных,
            политике оператора в отношении обработки персональных данных, локальным актам оператора;
        </li>
        <li>
            оценка вреда, который может быть причинен Субъектам персональных данных в случае нарушения ФЗ "О персональных данных", соотношение указанного вреда и принимаемых оператором мер, направленных на обеспечение выполнения
            обязанностей, предусмотренных ФЗ "О персональных данных";
        </li>
    </ul>

    <div class="paragraph">7.3. Обеспечение безопасности персональных данных достигается, в частности:</div>
    <ul class="ul__line">
        <li>определением угроз безопасности персональных данных при их обработке в информационных системах персональных данных;</li>
        <li>
            применением организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных, необходимых для выполнения требований к защите персональных данных,
            исполнение которых обеспечивает установленные Правительством Российской Федерации уровни защищенности персональных данных;
        </li>
        <li>применением прошедших в установленном порядке процедуру оценки соответствия средств защиты информации;</li>
        <li>оценкой эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы персональных данных;</li>
        <li>учетом машинных носителей персональных данных;</li>
        <li>обнаружением фактов несанкционированного доступа к персональным данным и принятием мер;</li>
        <li>восстановлением персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</li>
        <li>
            установлением правил доступа к персональным данным, обрабатываемым в информационной системе персональных данных, а также обеспечением регистрации и учета всех действий, совершаемых с персональными данными в информационной
            системе персональных данных;
        </li>
        <li>контролем за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности информационных систем персональных данных.</li>
    </ul>

    <div class="paragraph__main">8. Заключительные положения</div>
    <div class="paragraph">
        8.1. <span class="bold">Оператор</span> несёт гражданско-правовую, административную и иную ответственность за несоблюдение принципов и условий обработки персональных данных физических лиц, а также за разглашение или незаконное
        использование персональных данных в соответствии с законодательством Российской Федерации.
    </div>
    <div class="paragraph">8.2. Политика является общедоступной и подлежит размещению на интернет сайте, находящемся по адресу: https://${baseUrl} или иным образом обеспечивается неограниченный доступ к настоящему документу.</div>
</div>
`;

export const Subscribe = () => {
    const baseUrl = window.location.hostname;

    return <div dangerouslySetInnerHTML={{ __html: SUBSCRIBE(baseUrl) }}></div>;
};
