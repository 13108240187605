import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import App from './components/app/App';
import MailingPolicyPage from './components/app/MailingPolicy';
import PolicyPage from './components/app/Policy';
import Prepare from './components/app/Prepare';
import Showcase from './components/app/Showcase';
import Sms from './components/app/Sms';
import Thanks from './components/app/Thanks';
import './style/style.css';
import './style/null.css';
import { FeatureFlagProvider } from './providers/FeatureFlagProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    // <React.StrictMode>
    <FeatureFlagProvider>
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/anketa" element={<App />} />
                <Route path="/thanks" element={<Thanks />} />
                <Route path="/showcase" element={<Showcase />} />
                <Route path="/:slug" element={<App />} />
                <Route path="/prepare" element={<Navigate to="/" replace />} />
                <Route path="/prepare/:slug" element={<Prepare />} />
                <Route path="/policy" element={<PolicyPage />} />
                <Route path="/mailing-policy" element={<MailingPolicyPage />} />
                <Route path="/sms" element={<Sms />} />
            </Routes>
        </Router>
    </FeatureFlagProvider>,
    // </React.StrictMode>
);
