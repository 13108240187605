import { PropsWithChildren, FC } from 'react';

import Footer from './../footer/Footer';
import Header from './../header/Header';

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
};

export default Wrapper;
