import { FC, useState } from 'react';

import { Badge } from '../badge/Badge';

import './style.css';

import { inputTypes } from './types';

const Input: FC<inputTypes> = ({
    inputRef,
    title,
    description,
    placeholder,
    value,
    badgeContent,
    setValue,
    removeFocus,
    onPressKeyArrow = (e: any) => e.key === 'Enter' && e.target.blur(),
    isValid,
    inputClassName,
    readOnly,
}) => {
    const [focused, setFocused] = useState(false);

    return (
        <div
            className={`input ${
                isValid ? (isValid === true ? 'is-valid-ok' : 'is-valid-error') : ''
            }`}
        >
            <div className="input__info row jc-sb">
                <div className={`input__title ${focused ? 'active' : ''}`}>{title}</div>
                <div className="input__error">{typeof isValid === 'string' ? isValid : ''}</div>
            </div>
            {!!description && (
                <div className="input__info row jc-sb" style={{ paddingTop: 0 }}>
                    <div className="input__title">{description}</div>
                </div>
            )}
            <div className="input__input">
                <input
                    ref={inputRef}
                    readOnly={readOnly}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    className={inputClassName}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={(e) => {
                        removeFocus(e.target.value);
                        setFocused(false);
                    }}
                    onKeyDown={(e) => onPressKeyArrow(e)}
                    onFocus={() => {
                        setFocused(true);
                    }}
                />
                {badgeContent ? (
                    isValid ? (
                        isValid === true ? null : (
                            <Badge badgeContent={badgeContent} />
                        )
                    ) : focused ? (
                        <Badge badgeContent={badgeContent} />
                    ) : null
                ) : null}
            </div>
        </div>
    );
};

export default Input;
