import Footer from '../layout/footer/Footer';
import Header from '../layout/header/Header';
import SMSForm from '../layout/smsForm/smsForm';

const Sms = () => {
    return (
        <>
            <Header />
            <SMSForm />
            <Footer />
        </>
    );
};

export default Sms;
