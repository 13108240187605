import { FC, useMemo } from 'react';

import useCalcProgress from './../../../services/CalcProgress';
import './style.css';
import { progressTypes } from './types';

const Progress: FC<progressTypes> = ({ totalPoints, currentPoints, nextPoints, title }) => {
    const { getCurrentProgress, getNextProgress } = useCalcProgress(5, 100, totalPoints);

    const currentPercentage = useMemo(
        () => getCurrentProgress(currentPoints),
        [currentPoints, getCurrentProgress],
    );

    const nextPercentage = useMemo(
        () => getNextProgress(nextPoints),
        [nextPoints, getNextProgress],
    );

    return (
        <div className="form-progress">
            <h4 className="form-progress__title">{title}</h4>
            <div className="form-progress__value">{currentPercentage} %</div>
            <div className="form-progress__line form-progress-line">
                <span
                    className="form-progress-line__current"
                    style={{
                        width: `${currentPercentage}%`,
                    }}
                />
                <span
                    className="form-progress-line__next"
                    style={{
                        width: `${currentPercentage + nextPercentage}%`,
                    }}
                />
            </div>
        </div>
    );
};

export default Progress;
