import { FC } from 'react';

import useCalcPay from './../../../services/CalcPayService';
import './style.css';

interface Props {
    sum: string;
}

/** Срок кредита */
const YEARS = 5;
/** Процентная ставка */
const RATE = 6;

export const Calculator: FC<Props> = ({ sum }) => {
    const summa = parseInt(sum.replace(/([^\d])/g, ''));

    const { getMonthlyPay } = useCalcPay(summa, YEARS, RATE);
    const monthlyPay = getMonthlyPay();

    return (
        <div className="calculator">
            <div className="calculator__item">
                <div className="calculator__label">Сумма</div>
                <div className="calculator__value">
                    {summa.toLocaleString('ru-RU', {
                        currency: 'RUB',
                        style: 'currency',
                        maximumFractionDigits: 0,
                    })}
                </div>
            </div>
            <div className="calculator__item">
                <div className="calculator__label">Годовая ставка</div>
                <div className="calculator__value">{`От ${RATE}%`}</div>
            </div>
            <div className="calculator__item">
                <div className="calculator__label">Платеж</div>
                <div className="calculator__value">{`От ${monthlyPay.toLocaleString('ru-RU', {
                    currency: 'RUB',
                    style: 'currency',
                    maximumFractionDigits: 0,
                })}`}</div>
            </div>
        </div>
    );
};
