import { FC } from 'react';
import './style.css';

interface Props {
    onAccept: () => void;
}

const CookieBanner: FC<Props> = ({ onAccept }) => {
    return (
        <div className="mc_panel">
            <div className="mc_wrap">
                <div className="panel_text">
                    Пользуясь настоящим веб-сайтом, вы даете свое согласие на использование файлов
                    cookies
                </div>
                <div className="panel_btn">
                    <button className="mc_close_btn" onClick={onAccept}>
                        Понятно
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieBanner;
