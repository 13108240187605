import { FC, useContext, useEffect, useRef, useState } from 'react';
import { RenderSuggestion } from 'react-autosuggest';
import HighlightWords from 'react-highlight-words';

import {
    setOrganization,
    setOrganizationDependentFields,
    setWorkAddress,
    setWorkPhone,
    setWorkAddressDependentFields,
} from '../../../../actions/formQuest';
import {
    useDadataAddress,
    useDadataParty,
    useDadataPartyById,
} from '../../../../hooks/dadataApiHooks';
import { FormQuestContext } from '../../../../reducer/reducer';
import useStep1 from '../../../../services/formQuestServise/Step1Servise';
import useStep3 from '../../../../services/formQuestServise/Step3Servise';
import { SuggestInput } from '../../suggestInput/SuggestInput';

/** параметры дадаты */
const locations = [{ country_iso_code: 'RU' }];
const params = {
    count: 5,
    locations,
};

const paramsAddress = {
    count: 5,
    locations,
    from_bound: { value: 'region' },
    to_bound: { value: 'house' },
};

/** маппер для получения suggestionValue */
const getSuggestionValue = (suggestion: any) => suggestion.value;

/** рендер подсказки в списке */
const renderSuggestion: RenderSuggestion<any> = (suggestion, params) => {
    return (
        <div>
            <div>
                <HighlightWords
                    searchWords={[
                        new RegExp(
                            params.query.replace(
                                /([!@#$%^&*()+=\\[\]\\';,./{}|":<>?~_-])/g,
                                '\\$1',
                            ),
                            'g',
                        ),
                    ]}
                    textToHighlight={suggestion.value}
                />
            </div>
            <div className="react-dadata__suggestion-subtitle">
                {suggestion.data.inn && (
                    <div className="react-dadata__suggestion-subtitle-item">
                        {suggestion.data.inn} {` `}
                        {suggestion.data.address?.data.city_with_type ||
                            suggestion.data.address?.data.settlement_with_type ||
                            ''}
                    </div>
                )}
            </div>
        </div>
    );
};

interface IOrgProps {
    setShowWorkAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OrganizationSuggest: FC<IOrgProps> = ({ setShowWorkAddress }) => {
    const [focused, setFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const { data, trigger } = useDadataParty(params);
    const { trigger: triggerById } = useDadataPartyById(params);
    const { data: dataAddress, trigger: triggerAddress } = useDadataAddress(paramsAddress);

    const { state, dispatch } = useContext(FormQuestContext);

    const {
        organization,
        workAddress,
        workPhone,
        employment,
        workInn,
        workHead,
        workOpf,
        okvedMain,
    } = state;

    const { validationPhone, maskWorkGoodPhone } = useStep1();
    const { validationOrganisation, validateWorkAddress } = useStep3();

    const handleChange = (e: any, { newValue }: any) => {
        setOrganization(dispatch, organization, newValue, false, false);
        setOrganizationDependentFields(dispatch, {
            workInn: '',
            workHead: '',
            workCity: '',
            workStreet: '',
            workHouse: '',
            workOpf: '',
            workRegionKladr: '',
            workRegion: '',
            okvedMain: '',
            workCityKladr: '',
            workStreetKladr: '',
            workHouseKladr: '',
        });
    };

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        trigger(value);
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (e: any, data: any) => {
        const organizationValue = data.suggestion.value;
        const isOrganizationValid = validationOrganisation(
            organization.errorMessages,
            organizationValue,
            true,
        );

        setOrganization(dispatch, organization, organizationValue, isOrganizationValid, true);

        if (isOrganizationValid === true) {
            if (
                (employment.value === '58' && data.suggestion.data.opf?.short === 'ИП') ||
                Number(employment.value) >= 62
            ) {
                setOrganizationDependentFields(dispatch, {
                    workInn: data.suggestion.data.inn,
                    workHead:
                        data.suggestion.data.management?.name || data.suggestion.data.name?.full,
                    workCity: '',
                    workStreet: '',
                    workHouse: '',
                    workOpf: data.suggestion.data.opf?.short || '',
                    workRegionKladr: '',
                    workRegion: '',
                    okvedMain: data?.suggestion?.data?.okved || '',
                    workCityKladr: '',
                    workStreetKladr: '',
                    workHouseKladr: '',
                });
                return;
            }

            const isWorkAddressValid =
                validateWorkAddress(
                    workAddress.errorMessages,
                    {
                        value: data.suggestion.data?.address?.value || '',
                        city:
                            data.suggestion.data?.address?.data?.city ||
                            data.suggestion.data?.address?.data?.settlement,
                        street: data.suggestion.data?.address?.data?.street,
                        house: data.suggestion.data?.address?.data?.house,
                    },
                    true,
                ) === true;

            if (isWorkAddressValid) {
                setWorkAddress(
                    dispatch,
                    workAddress,
                    data.suggestion.data.address?.value || '',
                    isWorkAddressValid,
                    true,
                );
                setOrganizationDependentFields(dispatch, {
                    workInn: data.suggestion.data.inn,
                    workHead:
                        data.suggestion.data.management?.name || data.suggestion.data.name?.full,
                    workCity:
                        data.suggestion.data.address?.data.city ||
                        data.suggestion.data.address?.data.settlement ||
                        null,
                    workStreet: data.suggestion.data.address?.data.street || 'Нет',
                    workHouse: data.suggestion.data.address?.data.house || '',
                    workOpf: data.suggestion.data.opf?.short || '',
                    workRegionKladr: data.suggestion.data.address?.data.region_kladr_id || '',
                    workRegion: data.suggestion.data.address?.data.region_with_type || '',
                    okvedMain: data.suggestion.data.okved || '',
                    workCityKladr: data.suggestion.data.address?.data.city_kladr_id || '',
                    workStreetKladr: data.suggestion.data.address?.data.street_kladr_id || '',
                    workHouseKladr: data.suggestion.data.address?.data.house_kladr_id || '',
                });
                triggerById(data.suggestion.data.hid).then(({ suggestions }) => {
                    const suggestion = suggestions[0];

                    if (!suggestion) {
                        return;
                    }

                    const workPhoneValue = Array.isArray(suggestion.data.phones)
                        ? suggestion.data.phones[0].value ?? ''
                        : '';
                    const isPhoneValid = validationPhone(
                        workPhone.errorMessages,
                        maskWorkGoodPhone(workPhoneValue),
                    );

                    if (workPhoneValue && typeof isPhoneValid !== 'string') {
                        setWorkPhone(
                            dispatch,
                            workPhone,
                            maskWorkGoodPhone(workPhoneValue),
                            isPhoneValid,
                        );
                    }
                });
                setShowWorkAddress(false);
                localStorage.setItem('showWorkAddress', 'false');
            } else {
                if (
                    !data.suggestion.data?.address?.data?.city &&
                    !data.suggestion.data?.address?.data?.settlement
                ) {
                    setWorkAddress(
                        dispatch,
                        workAddress,
                        data.suggestion.data.address?.value || '',
                        false,
                        true,
                    );
                    setOrganizationDependentFields(dispatch, {
                        workInn: data.suggestion.data.inn,
                        workHead:
                            data.suggestion.data.management?.name ||
                            data.suggestion.data.name?.full,
                        workOpf: data.suggestion.data.opf?.short || '',
                        okvedMain: data.suggestion.data?.okved || '',
                        workCity:
                            data.suggestion.data?.address?.data?.city ||
                            data.suggestion.data?.address?.data?.settlement ||
                            null,
                        workStreet: data.suggestion.data.address?.data.street || 'Нет',
                        workHouse: data.suggestion.data.address?.data.house || '',
                        workRegionKladr: data.suggestion.data.address?.data.region_kladr_id || '',
                        workRegion: data.suggestion.data.address?.data.region_with_type || '',
                        workCityKladr: data.suggestion.data.address?.data.city_kladr_id || '',
                        workStreetKladr: data.suggestion.data.address?.data.street_kladr_id || '',
                        workHouseKladr: data.suggestion.data.address?.data.house_kladr_id || '',
                    });
                    triggerById(data.suggestion.data.hid).then(({ suggestions }) => {
                        const suggestion = suggestions[0];

                        if (!suggestion) {
                            return;
                        }

                        const workPhoneValue = Array.isArray(suggestion.data.phones)
                            ? suggestion.data.phones[0].value ?? ''
                            : '';
                        const isPhoneValid = validationPhone(
                            workPhone.errorMessages,
                            maskWorkGoodPhone(workPhoneValue),
                        );

                        if (workPhoneValue && typeof isPhoneValid !== 'string') {
                            setWorkPhone(
                                dispatch,
                                workPhone,
                                maskWorkGoodPhone(workPhoneValue),
                                isPhoneValid,
                            );
                        }
                    });
                    setShowWorkAddress(true);
                    localStorage.setItem('showWorkAddress', 'true');
                    return;
                }
                if (
                    !data.suggestion.data?.address?.data?.street ||
                    !data.suggestion.data?.address?.data?.house
                ) {
                    const city =
                        data.suggestion.data.address?.data.city ||
                        data.suggestion.data.address?.data.settlement;

                    const str: any = `г ${city} ул. Центральная, д 1`;
                    triggerAddress(str);

                    setWorkAddress(
                        dispatch,
                        workAddress,
                        data.suggestion.data.address?.value || '',
                        false,
                        true,
                    );
                    setOrganizationDependentFields(dispatch, {
                        workInn: data.suggestion.data.inn,
                        workHead:
                            data.suggestion.data.management?.name ||
                            data.suggestion.data.name?.full,
                        workOpf: data.suggestion.data.opf?.short || '',
                        okvedMain: data.suggestion.data?.okved || '',
                        workCity:
                            data.suggestion.data?.address?.data?.city ||
                            data.suggestion.data?.address?.data?.settlement ||
                            null,
                        workStreet: data.suggestion.data.address?.data.street || 'Нет',
                        workHouse: data.suggestion.data.address?.data.house || '',
                        workRegionKladr: data.suggestion.data.address?.data.region_kladr_id || '',
                        workRegion: data.suggestion.data.address?.data.region_with_type || '',
                        workCityKladr: data.suggestion.data.address?.data.city_kladr_id || '',
                        workStreetKladr: data.suggestion.data.address?.data.street_kladr_id || '',
                        workHouseKladr: data.suggestion.data.address?.data.house_kladr_id || '',
                    });

                    triggerById(data.suggestion.data.hid).then(({ suggestions }) => {
                        const suggestion = suggestions[0];

                        if (!suggestion) {
                            return;
                        }

                        const workPhoneValue = Array.isArray(suggestion.data.phones)
                            ? suggestion.data.phones[0].value ?? ''
                            : '';
                        const isPhoneValid = validationPhone(
                            workPhone.errorMessages,
                            maskWorkGoodPhone(workPhoneValue),
                        );

                        if (workPhoneValue && typeof isPhoneValid !== 'string') {
                            setWorkPhone(
                                dispatch,
                                workPhone,
                                maskWorkGoodPhone(workPhoneValue),
                                isPhoneValid,
                            );
                        }
                    });

                    return;
                }
            }
        }
    };

    useEffect(() => {
        if (data) {
            setSuggestions(data.suggestions);
        }
    }, [data]);

    useEffect(() => {
        const checkAddress = () => {
            const arr = dataAddress?.suggestions;
            for (let i = 0; i < arr?.length; i++) {
                const data = arr[i]?.data;
                if ((data?.city || data?.settlement) && data?.street && data?.house) {
                    setWorkAddress(dispatch, workAddress, arr[i].value || '', true, true);
                    setWorkAddressDependentFields(dispatch, {
                        workCity: data?.city || data?.settlement || null,
                        workStreet: data?.street || 'Нет',
                        workHouse: data?.house || '',
                        workRegionKladr: data?.region_kladr_id || '',
                        workRegion: data.address?.data.region_with_type || '',
                        workCityKladr: data?.city_kladr_id || '',
                        workStreetKladr: data?.street_kladr_id || '',
                        workHouseKladr: data?.house_kladr_id || '',
                    });
                    setShowWorkAddress(false);
                    return;
                }
            }
            if (arr && arr[0]) {
                const data = arr[0]?.data;
                setWorkAddress(dispatch, workAddress, '', false, true);
                setOrganizationDependentFields(dispatch, {
                    workCity: data?.city || data?.settlement || null,
                    workStreet: data?.street || 'Нет',
                    workHouse: data?.house || '',
                    workRegionKladr: data?.region_kladr_id || '',
                    workRegion: data?.region_with_type || '',
                    workCityKladr: data?.city_kladr_id || '',
                    workStreetKladr: data?.street_kladr_id || '',
                    workHouseKladr: data?.house_kladr_id || '',
                    workInn: workInn.value,
                    workHead: workHead.value,
                    workOpf: workOpf.value,
                    okvedMain: okvedMain.value,
                });
                return;
            }

            setWorkAddress(dispatch, workAddress, '', false, true);
            setShowWorkAddress(true);
        };
        if (dataAddress) {
            checkAddress();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataAddress]);

    const inputProps = {
        placeholder: 'Наименование работодателя',
        value: organization.value,
        onChange: handleChange,
        onFocus: () => {
            setFocused(true);

            if (!focused && inputRef.current) {
                const r = inputRef.current;

                setTimeout(() => {
                    r.setSelectionRange(organization.value.length, organization.value.length);
                }, 10);
            }
        },
        onBlur: () => {
            setFocused(false);
            setOrganization(
                dispatch,
                organization,
                organization.value,
                validationOrganisation(
                    organization.errorMessages,
                    organization.value,
                    organization.isDadata,
                ),
                organization.isDadata,
            );
        },
        ref: inputRef,
    };

    return (
        <SuggestInput
            label="Наименование работодателя"
            inputProps={inputProps}
            isValid={organization.isValid}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
        />
    );
};
