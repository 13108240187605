import { FC, PropsWithChildren } from 'react';

export const Anchor: FC<PropsWithChildren<{ name: string }>> = ({ name, children }) => (
    <>
        <a
            data-name={name}
            tabIndex={-1}
            style={{
                border: 0,
                clip: 'rect(0 0 0 0)',
                height: 1,
                margin: -1,
                overflow: 'hidden',
                padding: 0,
                position: 'absolute',
                whiteSpace: 'nowrap',
                width: 1,
            }}
        ></a>
        {children}
    </>
);
