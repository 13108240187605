import { Subscribe } from '../layout/policy/Subscribe';

import Wrapper from './../layout/wrapper/Wrapper';

const PolicyPage = () => {
    return (
        <Wrapper>
            <div style={{ maxWidth: 1140, margin: 'auto', padding: '20px 0' }}>
                <Subscribe />
            </div>
        </Wrapper>
    );
};

export default PolicyPage;
