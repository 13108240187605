import BannerFileProgress from '../../layout/banner/bannerFileProgress/BannerFileProgress';

export const FormStep3MiniView = () => {
    return (
        <div className="form-quest__content">
            <h3 className="form-quest__title">ПРОВЕРЯЕМ ВАШУ ЗАЯВКУ...</h3>
            <br />
            <BannerFileProgress />
        </div>
    );
};
