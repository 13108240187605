import { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useStep1 from '../../../services/formQuestServise/Step1Servise';
import useSend from '../../../services/SendService';
import Input from '../../elements/input/Input';

import '../../../style/style.css';
import './style.css';

import ButtonForm from './../../elements/button/buttonForm/ButtonForm';

interface ISMS {
    phone: boolean | string;
    code: boolean | string;
}

const startFormState: ISMS = {
    phone: false,
    code: false,
};

const regex = /^(\d{4})$/;

const SMSForm = () => {
    const phone = localStorage.getItem('phone') ? (localStorage.getItem('phone') as string) : '';

    const urlParams = new URLSearchParams(window.location.search);
    const dealId = urlParams.get('dealId');

    const navigate = useNavigate();
    const { codeCheck, codeSent } = useSend();
    const [code, setCode] = useState<string>('');
    const [getCode, setGetCode] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ISMS>(startFormState);
    const [codeAgain, setCodeAgain] = useState<boolean>(false);
    const [lastSentCode, setLastSentCode] = useState<null | string>(null);
    const [loading, setIsLoading] = useState<boolean>(false);

    const startTime = useRef<null | Date>(null);

    const [timer, setTimer] = useState<string>('1:00');

    const { maskPhone } = useStep1();

    const intervalRef = useRef<any>(null);

    const timerFunc = useCallback(() => {
        const dateNow = new Date();
        if (startTime.current) {
            const dif = startTime.current.getTime() - dateNow.getTime();

            const time = 60 - Math.abs(dif / 1000);

            const minutes = Math.floor(time / 60);

            const seconds =
                Math.floor(time % 60) < 10
                    ? `0${Math.floor(time % 60)}`
                    : `${Math.floor(time % 60)}`;

            if (time >= 0) {
                setTimer(`${minutes}:${seconds}`);
                setCodeAgain(false);
            } else {
                setTimer(`0:00`);
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
                setCodeAgain(true);
            }
        }
        return;
    }, []);

    useEffect(() => {
        if (intervalRef?.current) {
            return () => clearInterval(intervalRef.current);
        }
    }, [getCode, timerFunc]);

    const sentCode = async () => {
        const formData: any = new FormData();
        formData.append('id', dealId);
        codeSent(formData)
            .then((value) => {
                if (value?.result !== true) {
                    setErrorMessage({ phone: 'Заявка не найдена', code: true });
                    navigate('/');
                    return;
                }
                setLastSentCode(null);
                setErrorMessage({ phone: true, code: regex.test(code) });
                setGetCode(true);
                startTime.current = new Date();
                clearInterval(intervalRef.current);
                intervalRef.current = setInterval(timerFunc, 100);
            })
            .catch(() => {
                setErrorMessage({ phone: 'Ошибка отправки', code: false });
            });
    };

    const checkCode = async () => {
        const formData: any = new FormData();
        formData.append('id', dealId);
        formData.append('code', code);
        codeCheck(formData)
            .then((value) => {
                if (!value?.status && value.description === 'no match') {
                    setErrorMessage({ phone: false, code: 'Неверный код' });
                    setIsLoading(false);
                } else {
                    window.location.href = `${window.location.protocol}//${window.location.host}/vitrina-sms/`;
                    document.title =
                        'Реальный кредит с любой кредитной историей за 30 минут без залога';
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setErrorMessage({ phone: false, code: 'Ошибка отправки' });
                setIsLoading(false);
            });
    };

    const codeStatusFunc = (code: string) => {
        if (lastSentCode === null) {
            setErrorMessage({ ...errorMessage, code: errorMessage.code || regex.test(code) });
        }

        if (lastSentCode === code) {
            setErrorMessage({ ...errorMessage, code: 'Неверный код' });
        }

        if (lastSentCode !== code) {
            setErrorMessage({ ...errorMessage, code: regex.test(code) });
        }
    };

    return (
        <div className="sms">
            <h2>Подтвердите номер телефона</h2>
            <div className="sms__info">
                Подтвердите номер телефона, чтобы мы могли прислать смс с решением от банков. Мы
                гарантируем безопасность и сохранность ваших данных.
            </div>

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
                className="sms__form"
            >
                <div className="sms__send-text">Мы отправим Вам СМС с кодом подтверждения</div>
                <Input
                    title="Номер телефона"
                    placeholder=""
                    value={maskPhone(phone)}
                    setValue={() => ''}
                    removeFocus={() => ''}
                    isValid={false || errorMessage.phone}
                    readOnly
                />

                {getCode && (
                    <Input
                        title="Код подтверждения"
                        placeholder=""
                        value={code}
                        setValue={(str) => {
                            if (str.length < 5 && (/^\d+$/.test(str) || str === '')) {
                                setCode(str);
                                codeStatusFunc(str);
                            }
                        }}
                        removeFocus={() => ''}
                        isValid={errorMessage.code}
                    />
                )}

                {getCode ? (
                    <>
                        <ButtonForm
                            text={`${loading ? 'Отправка...' : 'Подтвердить'}`}
                            setFunction={() => {
                                if (
                                    (regex.test(code) && errorMessage.code !== 'Неверный код') ||
                                    !loading
                                ) {
                                    checkCode();
                                    setIsLoading(true);
                                    setLastSentCode(code);
                                }
                            }}
                            buttonClassName={`sms__submit-btn ${
                                (!regex.test(code) ||
                                    errorMessage.code === 'Неверный код' ||
                                    loading) &&
                                'disabled'
                            }`}
                        />

                        <ButtonForm
                            text={`Запросить повторно ${!codeAgain ? timer : ''}`}
                            setFunction={() => {
                                if (codeAgain) {
                                    sentCode();
                                }
                            }}
                            buttonClassName={`sms__submit-btn ${!codeAgain && 'disabled'}`}
                        />
                    </>
                ) : (
                    <ButtonForm
                        text="Получить код"
                        setFunction={() => {
                            sentCode();
                        }}
                        buttonClassName="sms__submit-btn"
                    />
                )}
            </form>
        </div>
    );
};

export default SMSForm;
