const _token = 'f7cd59d270fa2905f74cf38f74981c3abbe346c8';

export const postFetcher = (url: string, params: any) =>
    fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
        mode: 'cors' as RequestMode,
        headers: {
            'content-type': 'application/json',
            Authorization: `Token ${_token}`,
        },
    }).then((res) => {
        return res.json();
    });
