import { FC, PropsWithChildren } from 'react';

import './style.css';
import odobrenoImg from './../../../assets/imgs/form/odobreno-img.png';

interface Props {
    note: string;
    success?: boolean;
}

export const StepHeader: FC<PropsWithChildren<Props>> = ({ note, success, children }) => {
    return (
        <div className="step-header">
            <img className="step-header__logo" src={odobrenoImg} alt="" />
            <div className="step-header__title">КРЕДИТ ПРЕДВАРИТЕЛЬНО ОДОБРЕН!</div>
            <div className="step-header__body">{children}</div>
            <div className={`step-header__note ${success ? 'step-header--success' : ''}`}>
                {note}
            </div>
        </div>
    );
};
