import './style.css';

const BannerFileProgress = () => {
    return (
        <div className="container move">
            <svg
                className="file fadein"
                x="0px"
                y="0px"
                width="36px"
                height="43px"
                viewBox="0 0 36 43"
            >
                <path className="file-body" d="M36,43H0V0h24.7L36,11.3V43z" />
                <path className="file-bend" d="M24.7,0L36,11.3H24.7V0z" />
            </svg>
            <svg
                className="file zoomin"
                x="0px"
                y="0px"
                width="36px"
                height="43px"
                viewBox="0 0 36 43"
            >
                <path className="file-body" d="M36,43H0V0h24.7L36,11.3V43z" />
                <path className="file-bend" d="M24.7,0L36,11.3H24.7V0z" />
            </svg>
            <svg
                className="file zoomout"
                x="0px"
                y="0px"
                width="36px"
                height="43px"
                viewBox="0 0 36 43"
            >
                <path className="file-body" d="M36,43H0V0h24.7L36,11.3V43z" />
                <path className="file-bend" d="M24.7,0L36,11.3H24.7V0z" />
                <path
                    className="file-check done"
                    d="M26.8,20.6L14.9,32.5l-5.7-5.7l2.1-2.1l3.6,3.6l9.8-9.9L26.8,20.6z"
                />
                <circle className="file-loading load" cx="13" cy="21" r="7.7"></circle>
            </svg>
            <svg
                className="file fadeout"
                x="0px"
                y="0px"
                width="36px"
                height="43px"
                viewBox="0 0 36 43"
            >
                <path className="file-body" d="M36,43H0V0h24.7L36,11.3V43z" />
                <path className="file-bend" d="M24.7,0L36,11.3H24.7V0z" />
                <path
                    className="file-check"
                    d="M26.8,20.6L14.9,32.5l-5.7-5.7l2.1-2.1l3.6,3.6l9.8-9.9L26.8,20.6z"
                />
            </svg>
        </div>
    );
};

export default BannerFileProgress;
