import img01 from './../../../assets/imgs/showcase/img01.png';
import img02 from './../../../assets/imgs/showcase/img02.png';
import img03 from './../../../assets/imgs/showcase/img03.png';
import img04 from './../../../assets/imgs/showcase/img04.png';
import img05 from './../../../assets/imgs/showcase/img05.png';
import img06 from './../../../assets/imgs/showcase/img06.png';
import img07 from './../../../assets/imgs/showcase/img07.png';
import img08 from './../../../assets/imgs/showcase/img08.png';
import img09 from './../../../assets/imgs/showcase/img09.png';
import img10 from './../../../assets/imgs/showcase/img10.png';
import img11 from './../../../assets/imgs/showcase/img11.png';
import img12 from './../../../assets/imgs/showcase/img12.png';
import img13 from './../../../assets/imgs/showcase/img13.png';
import img14 from './../../../assets/imgs/showcase/img14.png';
import img15 from './../../../assets/imgs/showcase/img15.png';
import img16 from './../../../assets/imgs/showcase/img16.png';
import img17 from './../../../assets/imgs/showcase/img17.png';
import img18 from './../../../assets/imgs/showcase/img18.png';
import img19 from './../../../assets/imgs/showcase/img19.png';
import img20 from './../../../assets/imgs/showcase/img20.png';
import img21 from './../../../assets/imgs/showcase/img21.png';
import img22 from './../../../assets/imgs/showcase/img22.png';
import img23 from './../../../assets/imgs/showcase/img23.png';
import img24 from './../../../assets/imgs/showcase/img24.png';
import img25 from './../../../assets/imgs/showcase/img25.png';
import img26 from './../../../assets/imgs/showcase/img26.png';
import img27 from './../../../assets/imgs/showcase/img27.png';
import img28 from './../../../assets/imgs/showcase/img28.png';
import img29 from './../../../assets/imgs/showcase/img29.png';
import img30 from './../../../assets/imgs/showcase/img30.png';
import img31 from './../../../assets/imgs/showcase/img31.png';

export const list1 = [
    {
        sticker: 'Выгодные условия!',
        img: img01,
        offer1: 'от 1 000 000 р',
        offer2: 'от 1 000 000 р',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(98, 208, 67)',
        btnText: 'Получить кредит',
        link: '/go.php?id=febdc233cf5ae5282b3f9f74fed5a08d&subid1=vitr_first&subid2=267',
    },
    {
        sticker: '120 дней без %',
        img: img02,
        offer1: 'до 1 000 000 р',
        offer2: 'до 1 000 000 р',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(98, 208, 67)',
        btnText: 'Получить карту',
        link: '/go.php?id=b58fcc338f133a1ab381d37c044b2153&subid1=vitr_first&subid2=267',
    },
    {
        img: img03,
        offer1: 'до 100 000 000 р',
        offer2: 'до 100 000 000 р',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить кредит',
        link: '/go.php?id=80cf77e49f2862e7cb841a7507c0a534&subid1=vitr_first&subid2=267',
    },
    {
        img: img04,
        offer1: 'до 1 000 000 р',
        offer2: 'до 1 000 000 р',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить карту',
        link: '/go.php?id=d040d0936f33f6fef9b5e7d6be73b823&subid1=vitr_first&subid2=267',
    },
    {
        img: img05,
        offer1: 'Проверка',
        offer2: 'Проверка',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Проверить!',
        link: '/go.php?id=91831c2b854f8bd121767ed74768935c&subid1=vitr_first&subid2=267',
    },
    {
        img: img06,
        offer1: 'до 1 000 000р.',
        offer2: 'до 1 000 000р.',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить карту',
        link: '/go.php?id=c9ef30e07531d419cf89ad8fa91ef1e6&subid1=vitr_first&subid2=267',
    },
    {
        img: img07,
        offer1: 'Юридическая помощь',
        offer2: 'Юридическая помощь',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Оставить заявку',
        link: '/go.php?id=7d443106f6ad9efa3338084f03f9695b&subid1=vitr_first&subid2=267',
    },
    {
        sticker: 'Под залог ПТС',
        img: img08,
        offer1: 'до 10 000 000 р',
        offer2: 'до 10 000 000 р',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(69, 161, 242)',
        btnText: 'Получить кредит',
        link: '/go.php?id=7ce704187ea8bd2027ec89121af64d19&subid1=vitr_first&subid2=267',
    },
    {
        sticker: 'Кэшбэк до 30%',
        img: img09,
        offer1: 'до 700 000 р',
        offer2: 'до 700 000 р',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить карту',
        link: '/go.php?id=481bbb1b260b3386a434d820dd7e97df&subid1=vitr_first&subid2=267',
    },
    {
        sticker: 'Под залог авто',
        img: img10,
        offer1: 'до 1 000 000 р',
        offer2: 'до 1 000 000 р',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(69, 161, 242)',
        btnText: 'Оформить займ',
        link: '/go.php?id=f2e6354d6cd1a214aaf74589fa79fa51&subid1=vitr_first&subid2=267',
    },
    {
        img: img11,
        offer1: 'до 5 000 000р',
        offer2: 'до 5 000 000р',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить деньги',
        link: '/go.php?id=521a909a7475bfe9c2b07de3f83a2f4e&subid1=vitr_first&subid2=267',
    },
    {
        img: img12,
        offer1: 'до 500 000 р',
        offer2: 'до 500 000 р',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить кредит',
        link: '/go.php?id=ee6556ba8ae2b8ee03dd5f3c1e50dee7&subid1=vitr_first&subid2=267',
    },
    {
        sticker: 'Лучшие условия!',
        img: img13,
        offer1: 'Сервис подбора карт',
        offer2: 'Сервис подбора карт',
        text1: 'Кредит сроком до 5 лет',
        text2: 'Деньги на карту за 1 минуту',
        text3: 'Только паспорт и анкета',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Оставить заявку',
        link: '/go.php?id=3e370501936ed4cd5342fb1fc253cdca&subid1=vitr_first&subid2=267',
    },
];

export const list2 = [
    {
        img: img29,
        offer1: 'до 80 000 р.',
        offer2: 'до 80 000 р.',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить кредит',
        link: '/go.php?id=401624bbdc70dbe14bf8b99afd4d2883&subid1=vitr_first&subid2=267',
    },
    {
        sticker: 'Деньги за 5 минут!',
        img: img14,
        offer1: 'до 100 000 р',
        offer2: 'до 100 000 р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить деньги',
        link: '/go.php?id=280911ce55c1eebef251ac22272319da&subid1=vitr_first&subid2=267',
    },
    {
        img: img15,
        offer1: 'до 500 000р',
        offer2: 'до 500 000р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить займ',
        link: '/go.php?id=ab1eb5e85461649e87007b21a4122192&subid1=vitr_first&subid2=267',
    },
    {
        img: img16,
        offer1: 'до 500 000 р.',
        offer2: 'до 500 000 р.',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить кредит',
        link: '/go.php?id=fb1a9c3e65d9586f7730ecf873658648&subid1=vitr_first&subid2=267',
    },
    {
        img: img17,
        offer1: 'до 30 000 р.',
        offer2: 'до 30 000 р.',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить кредит',
        link: '/go.php?id=1afafb010cc99792daa9b074fbc909f4&subid1=vitr_first&subid2=267',
    },
    {
        img: img18,
        offer1: 'до 30 000 руб.',
        offer2: 'до 30 000 руб.',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить займ',
        link: '/go.php?id=a58949ee396bb32de5d34eabf1a5c975&subid1=vitr_first&subid2=267',
    },
    {
        img: img19,
        offer1: 'до 30 000р',
        offer2: 'до 30 000р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить займ',
        link: '/go.php?id=4323961f0e6bc3a9b1a28d83fa09ca63&subid1=vitr_first&subid2=267',
    },
    {
        img: img20,
        offer1: 'до 100 000 р',
        offer2: 'до 100 000 р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить займ',
        link: '/go.php?id=0c0473fbbae755c6fc6d962fed10d045&subid1=vitr_first&subid2=267',
    },
    {
        img: img21,
        offer1: 'до 100 000 р',
        offer2: 'до 100 000 р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить займ',
        link: '/go.php?id=1966d8508664fbb7c3434c1c67aff277&subid1=vitr_first&subid2=267',
    },
    {
        img: img22,
        offer1: 'до 100 000р',
        offer2: 'до 100 000р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить займ',
        link: '/go.php?id=96af600c42106d481d4ba6ad9a590fed&subid1=vitr_first&subid2=267',
    },
    {
        sticker: 'Карта за 5 минут!',
        img: img23,
        offer1: 'до 100 000 р',
        offer2: 'до 100 000 р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить карту',
        link: '/go.php?id=cc486778d3e4e6fde63dc1c12c6d34a4&subid1=vitr_first&subid2=267',
    },
    {
        img: img24,
        offer1: 'до 30 000 р',
        offer2: 'до 30 000 р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить деньги',
        link: '/go.php?id=1fcff729d496cd5d8e8d42fba9745ba1&subid1=vitr_first&subid2=267',
    },
    {
        img: img25,
        offer1: 'до 30 000 р',
        offer2: 'до 30 000 р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить Займ',
        link: '/go.php?id=cd978d28eda308c128b9f3c0ebb3447f&subid1=vitr_first&subid2=267',
    },
    {
        img: img26,
        offer1: 'до 30 000 р',
        offer2: 'до 30 000 р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить займ',
        link: '/go.php?id=462ca246c7df2e942eff31d0a01cd7b3&subid1=vitr_first&subid2=267',
    },
    {
        img: img27,
        offer1: 'до 30 000 р',
        offer2: 'до 30 000 р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить деньги',
        link: '/go.php?id=415c2d1e215aebf3a17692313ca4ac82&subid1=vitr_first&subid2=267',
    },
    {
        img: img28,
        offer1: 'до 70 000 р',
        offer2: 'до 70 000 р',
        text1: 'Без скрытых списаний',
        text2: 'Деньги за 7 минут',
        text3: 'На карту любого банка',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить деньги',
        link: '/go.php?id=884460790af306252cf6ff61f4be2aaf&subid1=vitr_first&subid2=267',
    },
];

export const list3 = [
    {
        img: img30,
        offer1: 'Карта Tinkoff Black',
        offer2: 'Карта Tinkoff Black',
        text1: 'Кэшбек до 30%',
        text2: 'до 6% годовых на остаток',
        text3: '0р снятие наличных',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить карту',
        link: '/go.php?id=31f69028ea69f4a6f54ebd07fd349434&subid1=vitr_first&subid2=267',
    },
    {
        img: img31,
        offer1: 'Мультикарта «Мир»',
        offer2: 'Мультикарта «Мир»',
        text1: 'Кэшбек до 30%',
        text2: 'до 6% годовых на остаток',
        text3: '0р снятие наличных',
        bgColor: 'rgb(255, 153, 0)',
        btnText: 'Получить карту',
        link: '/go.php?id=e7a8f979e91733f75216a5bf5e69df38&subid1=vitr_first&subid2=267',
    },
];
