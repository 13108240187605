import useSWRMutation from 'swr/mutation';
import { useDebouncedCallback } from 'use-debounce';

import { dadataApi } from './../constants/endpoints';
import { postFetcher } from './../utils/restUtils';

export const useDadataNames = (params: any) => {
    const postFetcherDebounced = useDebouncedCallback(
        (url, { arg }) => postFetcher(url, { ...params, query: arg }),
        200,
        {
            leading: true,
            trailing: true,
        },
    );
    const { data, error, trigger } = useSWRMutation(dadataApi.name, postFetcherDebounced);

    return { data, error, trigger };
};

export const useDadataAddress = (params: any) => {
    const postFetcherDebounced = useDebouncedCallback(
        (url, { arg }) => postFetcher(url, { ...params, query: arg }),
        200,
        {
            leading: true,
            trailing: true,
        },
    );
    const { data, error, trigger } = useSWRMutation(dadataApi.address, postFetcherDebounced);

    return { data, error, trigger };
};

export const useDadataParty = (params: any) => {
    const postFetcherDebounced = useDebouncedCallback(
        (url, { arg }) => postFetcher(url, { ...params, query: arg }),
        200,
        {
            leading: true,
            trailing: true,
        },
    );
    const { data, error, trigger } = useSWRMutation(dadataApi.party, postFetcherDebounced);

    return { data, error, trigger };
};

export const useDadataPartyById = (params: any) => {
    const postFetcherDebounced = useDebouncedCallback(
        (url, { arg }) => postFetcher(url, { ...params, query: arg }),
        200,
        {
            leading: true,
            trailing: true,
        },
    );
    const { data, error, trigger } = useSWRMutation(dadataApi.findById, postFetcherDebounced);

    return { data, error, trigger };
};

export const useDadataEmail = (params: any) => {
    const postFetcherDebounced = useDebouncedCallback(
        (url, { arg }) => postFetcher(url, { ...params, query: arg }),
        200,
        {
            leading: true,
            trailing: true,
        },
    );
    const { data, error, trigger } = useSWRMutation(dadataApi.email, postFetcherDebounced);

    return { data, error, trigger };
};

export const useDadataFmsUnit = (params: any) => {
    const postFetcherDebounced = useDebouncedCallback(
        (url, { arg }) => postFetcher(url, { ...params, query: arg }),
        200,
        {
            leading: true,
            trailing: true,
        },
    );
    const { data, error, trigger } = useSWRMutation(dadataApi.fms_unit, postFetcherDebounced);

    return { data, error, trigger };
};
