import { FC, useContext, useRef } from 'react';

import {
    ContactPersonFioSuggest,
    PlaceOfBirthSuggest,
    UnitCodeSuggest,
} from '../../elements/field';

import {
    setPassportNumberSeries,
    setDateOfIssue,
    setIssuedBy,
    setContactPhone,
    setContactPerson,
    setContactPersonFio,
} from './../../../actions/formQuest';
import { FeatureFlagContext } from './../../../providers/FeatureFlagProvider';
import { FormQuestContext } from './../../../reducer/reducer';
import useStep1 from './../../../services/formQuestServise/Step1Servise';
import useStep4 from './../../../services/formQuestServise/Step4Servise';
import { Anchor } from './../../elements/anchor/Anchor';
import ButtonForm from './../../elements/button/buttonForm/ButtonForm';
import Input from './../../elements/input/Input';
import SelectForm from './../../elements/select/selectForm/SelectForm';
import Textarea from './../../elements/textarea/Textarea';
import { Calculator } from './../../layout/calculator/Calculator';
import { StepHeader } from './../../layout/stepHeader/StepHeader';
import { formStepTypes } from './../types';

const optionsContactPerson = [
    { value: 'none', label: '-', isDisabled: true },
    { value: '226', label: 'Мой номер' },
    { value: '222', label: 'Коллега' },
    { value: '224', label: 'Родственник' },
    { value: '220', label: 'Друг' },
];

const ContactPhoneDescription = (
    <>
        На выбор: <i>свой второй мобильный, домашний телефон, номер знакомого либо родственника</i>
    </>
);

const FormStep4: FC<formStepTypes> = ({ setStep, setBack }) => {
    const flags = useContext(FeatureFlagContext);
    const { state, dispatch } = useContext(FormQuestContext);
    const {
        passportNumberSeries,
        dateOfIssue,
        dateBirth,
        issuedBy,
        sum,
        contactPhone,
        contactPerson,
        contactPersonFio,
        phone,
        workPhone,
    } = state;

    const { maskPhone } = useStep1();
    const {
        maskPassportNumberSeries,
        validationPassportNumberSeries,
        maskDateOfIssue,
        validationDateOfIssue,
        validationContactPhone,
    } = useStep4();

    const onSetPassportNumberSeries = (value: string) => {
        setPassportNumberSeries(
            dispatch,
            passportNumberSeries,
            maskPassportNumberSeries(value),
            false,
        );
    };
    const onRemoveFocusPassportNumberSeries = (value: string) => {
        const isValid = validationPassportNumberSeries(passportNumberSeries.errorMessages, value);
        setPassportNumberSeries(
            dispatch,
            passportNumberSeries,
            maskPassportNumberSeries(value),
            isValid,
        );
    };

    const onSetDateOfIssue = (value: string) => {
        setDateOfIssue(dispatch, dateOfIssue, maskDateOfIssue(value), false);
    };
    const onRemoveFocusDateOfIssue = (value: string) => {
        const isValid = validationDateOfIssue(
            dateOfIssue.errorMessages,
            value,
            dateBirth.value,
            passportNumberSeries.value,
        );
        setDateOfIssue(dispatch, dateOfIssue, maskDateOfIssue(value), isValid);
    };

    const onSetIssuedBy = (value: string) => {
        setIssuedBy(dispatch, dateOfIssue, value, false);
    };

    const onRemoveFocusIssuedBy = (value: string) => {
        const isValid = issuedBy.value !== '' || dateOfIssue.errorMessages.default;
        setIssuedBy(dispatch, issuedBy, value, isValid);
    };

    const onSetContactPhone = (value: string) => {
        setContactPhone(dispatch, contactPhone, maskPhone(value), false);
    };

    const onBlurContactPhone = (value: string) => {
        const isValid = validationContactPhone(
            contactPhone.errorMessages,
            value,
            value === phone.value || value === workPhone.value,
        );

        setContactPhone(dispatch, contactPhone, maskPhone(value), isValid);

        if (value === '') {
            setContactPersonFio(dispatch, contactPersonFio, '', false);
        }
    };

    const onSetContactPerson = (value: string) => {
        if (value === 'true') {
            return;
        }
        if (value === 'false') {
            value = contactPerson.value;
        }
        const isValid = value !== 'none' || contactPerson.errorMessages.choice;
        setContactPerson(dispatch, contactPerson, value, isValid);

        if (value === '226') {
            setContactPersonFio(dispatch, contactPersonFio, '', false);
        }
    };

    const onBackStep = () => {
        setBack(3);
        // setFullStep(dispatch, step4Fields);
    };

    const ref1 = useRef<any>(null);

    return (
        <div className="form-step-4 form-step">
            {flags?.disableCustomSteps ? null : (
                <StepHeader
                    note="Остался последний шаг! Заполните его для АВТОМАТИЧЕСКОГО одобрения кредита"
                    success
                >
                    <Calculator sum={sum.value} />
                </StepHeader>
            )}
            <Anchor name="passportNumberSeries">
                <Input
                    title="Серия номер паспорта"
                    placeholder="____ ______"
                    value={passportNumberSeries.value}
                    setValue={onSetPassportNumberSeries}
                    removeFocus={onRemoveFocusPassportNumberSeries}
                    isValid={passportNumberSeries.isValid}
                />
            </Anchor>
            <Anchor name="dateOfIssue">
                <Input
                    title="Дата выдачи"
                    placeholder="дд.мм.гггг"
                    value={dateOfIssue.value}
                    setValue={onSetDateOfIssue}
                    removeFocus={onRemoveFocusDateOfIssue}
                    isValid={dateOfIssue.isValid}
                />
            </Anchor>
            <Anchor name="unitCode">
                <UnitCodeSuggest />
            </Anchor>
            <Anchor name="issuedBy">
                <Textarea
                    title="Кем выдан"
                    placeholder="Кем выдан"
                    value={issuedBy.value}
                    setValue={onSetIssuedBy}
                    removeFocus={onRemoveFocusIssuedBy}
                    isValid={issuedBy.isValid}
                />
            </Anchor>
            <Anchor name="placeOfBirth">
                <PlaceOfBirthSuggest />
            </Anchor>
            <Anchor name="contactPhone">
                <Input
                    title="Дополнительный телефон"
                    description={ContactPhoneDescription}
                    placeholder="+7 (___) ___-__-__"
                    value={contactPhone.value}
                    setValue={onSetContactPhone}
                    removeFocus={onBlurContactPhone}
                    isValid={contactPhone.isValid}
                />
            </Anchor>
            {contactPhone.value.length === 18 && (
                <Anchor name="contactPerson">
                    <SelectForm
                        selectRef={ref1}
                        title="Владелец номера"
                        options={optionsContactPerson}
                        defaultOptions={contactPerson.value}
                        isValid={contactPerson.isValid}
                        setValue={onSetContactPerson}
                    />
                </Anchor>
            )}
            {contactPhone.value.length === 18 && contactPerson.value !== '226' && (
                <Anchor name="contactPersonFio">
                    <ContactPersonFioSuggest />
                </Anchor>
            )}
            <div className="form-step__btns row">
                <div className="form-step-back" onClick={onBackStep}>
                    Назад
                </div>
                <ButtonForm
                    text="Получить кредит"
                    buttonClassName="last-step-btn"
                    setFunction={setStep}
                />
            </div>
        </div>
    );
};

export default FormStep4;
