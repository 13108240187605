import { useEffect, useState } from 'react';

import BannerFileProgress from '../bannerFileProgress/BannerFileProgress';
import './style.css';

const STATUS_LIST = [
    'Оценка кредитной истории ...',
    'Проверка службы безопасности...',
    'Оценка платежеспособности ...',
    'Проверка стоп-факторов...',
    'Подбираем доступные программы...',
];

const BannerLoading = () => {
    const [task, setTask] = useState(1);

    const activeTasks = STATUS_LIST.slice(0, task);

    useEffect(() => {
        if (task > STATUS_LIST.length) {
            return;
        }

        const timeout = 3000 + Math.trunc(500 * Math.random());

        const timeoutId = setTimeout(() => {
            setTask((task) => task + 1);
        }, timeout);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [task]);

    return (
        <div className="banner-loading__content">
            <h3 className="form-quest__title">ЗАГРУЗКА ИНФОРМАЦИИ ПО ЗАЯВКЕ...</h3>
            <div className="banner-loading__loader">
                <div className="loader__wrapper">
                    <BannerFileProgress />
                </div>
            </div>
            <ul className="payment-calculation__list">
                {activeTasks.map((item, index) => {
                    return (
                        <li key={item} className="payment-calculation__item column jc-sb">
                            <div className="payment-calculation__name">
                                {item} {activeTasks.length - 1 === index ? ' 🕑' : ' ✅'}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default BannerLoading;
