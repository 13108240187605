export const MailingPolicy = () => {
    const baseUrl = window.location.hostname;
    const baseEmail = `info@${baseUrl}`;

    return (
        <div>
            <p>
                <strong>СОГЛАСИЕ НА ПОЛУЧЕНИЕ РЕКЛАМНО-ИНФОРМАЦИОННЫХ СООБЩЕНИЙ</strong>
            </p>
            <p>
                Настоящим я, (далее – «Субъект Персональных Данных», Пользователь), во исполнение
                требований ч. 1 ст. 18 Федерального закона от 13.03.2006 N 38-ФЗ «О рекламе» (с
                изменениями и дополнениями) действуя добровольно, свободно, своей волей и в своем
                интересе, а также подтверждая свою дееспособность предоставляю обществу с
                ограниченной ответственностью «Кредитмарт» идентификационный номер налогоплательщика
                7725321452, основной государственный регистрационный номер 1167746596896, а также
                Индивидуальному Предпринимателю Крымскому Павлу Васильевчиу, идентификационный номер
                налогоплательщика 772577914404, основной государственный регистрационный номер
                322774600321768 (далее по тексту - оператор персональных данных, Оператор),
                предварительное согласие на получение мною рекламно-информационных сообщений на мой
                абонентский номер и обработку в указанной цели моих персональных данных (данных
                Пользователя) в порядке и на нижеизложенных условиях, доведенных до моего сведения.
            </p>
            <p>
                1. Согласие дается на:
                <br />
                a) получение рекламно-информационных рассылок (сообщений) от Оператора, партнеров
                Оператора, следующими способами:
                <br />
                <br />
                - sms-рассылки;
                <br />
                <br />
                - push-сообщений (коротких текстовых уведомлений, передаваемых на мобильное
                персональное устройство с использованием технологии Google Cloud Messaging для
                операционной системы Android OS или технологии Apple Push Notification Service для
                операционной системы iOS);
                <br />
                <br />
                - по электронной почте;
                <br />
                <br />
                - по сетям электросвязи, в том числе посредством использования телефонной,
                факсимильной, подвижной радиотелефонной связи;
                <br />
                <br />- сообщений в социальных сетях (Вконтакте и Одноклассники (ООО «Мэйл.Ру») и{' '}
                <strong>мессенджерах Whatsapp, Telegram, Viber,</strong>
                <br />
                <br />
                - посредством отправки сообщений (в т.ч. голосовых) на указанный мною номер
                мобильного телефона, в том числе с использованием технологии IVR.
                <br />
                <br />
                б) обработку моих персональных данных Пользователя, а именно:
                <br />
                <br />- адреса электронной почты; (e-mail) и/или номера телефона, в соответствии с
                Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных»
            </p>

            <p>
                2. Настоящим я гарантию, что указанный мною на Сайте {baseUrl} абонентский номер
                телефона принадлежат мне, в случае прекращения использования мною абонентского
                номера телефона, указанного на Сайте, я обязуюсь проинформировать об этом Оператора
            </p>

            <p>
                3. Цель обработки персональных данных: осуществление информирования Субъекта
                Персональных Данных о финансовых и иных услугах, предлагаемых партнерами Оператора;
                продвижения финансовых услуг или иных продуктов партнеров Оператора, в том числе на
                предоставление микрофинансирования, потребительского займа, получение информационных
                материалов о наступление сроков выполнения обязательств по договору
                микрофинансирования, возникновении и/или наличии просроченной задолженности с
                указанием суммы, иной информации, связанной с выполнением мной условий договора
                микрозайма (потребительского займа).
            </p>

            <p>
                4. В ходе работы с персональными данными могут быть совершены следующие действия:
                сбор, запись, систематизация, накопление, хранение, уточнение (обновление,
                изменение), извлечение, использование, передача (распространение, предоставление,
                доступ), блокирование, удаление, уничтожение.
            </p>

            <p>
                5. Пользователь предоставляет персональные данные на Сайте, осознавая, что сеть
                Интернет является средой, не исключающей возникновение программных сбоев и не
                имеющей абсолютной защиты от неправомерных действий третьих лиц, что исключает
                гарантии Пользователю со стороны Оператора в абсолютной конфиденциальности, а равно
                сохранности/неизменности данных Пользователя, размещённых на Сайте. Давая настоящее
                согласие, Пользователь санкционирует размещение персональных данных на Сайте под
                свою личную ответственность.
            </p>

            <p>
                6. Настоящим я даю согласие Оператору и партнерами Оператора на передачу моих
                персональных данных в целях распространения рекламно-информационных материалов
                (Оператор вправе передать и/или поручить обработку персональных данных Пользователя)
                третьим лицам, включая операторов связи (при условии соблюдения режима
                конфиденциальности персональных данных, а также при условии, что таковое не нарушает
                законные права и интересы Пользователя и положения действующего законодательства), в
                том числе:
                <br />
                <br />
                - ПАО «МТС» (адрес: 109147, г. Москва, ул. Марксистская, д. 4);
                <br />
                <br />
                - ПАО «ВымпелКом» (адрес: 127083, г. Москва, ул. 8 Марта, д. 10, стр. 14);
                <br />
                <br />
                - ПАО «МегаФон» (адрес: 127006, г. Москва, пер. Оружейный, д. 41);
                <br />
                <br />
                - ООО «Т2 Мобайл» (адрес: 108811, г. Москва, километр Киевское шоссе 22-й (п.
                Московский),
                <br />
                <br />
                домовладение 6, стр. 1, эт. 5 комн. 33);
                <br />
                <br />
                Третьи лица (партнеры Оператора) и операторы связи, привлеченные Оператором,
                осуществляют обработку персональных данных, предоставляемых Оператором, в объеме, в
                целях и способами, установленными в настоящем согласии.
            </p>

            <p>
                7. Способ предоставления субъектом персональных данных Согласия на получение
                рекламно-информационных рассылок (сообщений) от Оператора, партнеров Оператора.
                <br />
                <br />
                Я соглашаюсь и обязуюсь подтвердить свое согласие, как субъекта персональных данных
                на получение рекламно-информационных рассылок (сообщений) одним или несколькими из
                нижеприведённых способов по своему выбору, что будет подтверждать полное прочтение
                текста Согласия и предоставления мной Оператору, партнерам Оператора согласия
                получение рекламно-информационных рассылок (сообщений):
                <br />
                <br />- путем прохождения авторизации и (или) аутентификации на сайте Оператора,
                размещённом в телекоммуникационной сети «Интернет» по адресу: {baseUrl},
                <br />
                <br />- путем проставления отметки (нажатием соответствующей электронной кнопки в
                интерфейсе сайта Оператора, размещённом в телекоммуникационной сети «Интернет» по
                адресу: {baseUrl} («принять», «согласен» и т.п.)) в «боксе», расположенном напротив
                текста: «Согласие на получение рекламно-информационных рассылок (сообщений);
                <br />
                <br />- путем перехода по Гиперссылке{' '}
                <a href="/mailing-policy" target="_blank">
                    https://{baseUrl}/mailing-policy
                </a>
                , расположенной на сайте Оператора в телекоммуникационной сети «Интернет» по адресу:
                {baseUrl}, на текст Согласия и далее осуществить действия: по загрузке на свой
                персональный компьютер текста Согласия на получение рекламно-информационных рассылок
                (сообщений); заполнению сведений о себе (ФИО, отчество (при наличии), номер
                основного документа удостоверяющего личность, сведения о дате выдаче, указанного
                документа и выдавшем его органе, адрес и дата рождения субъекта персональных
                данных), после чего подписать Согласие в виде электронного документа с помочью
                простой электронной подписи либо усиленной электронной подписи, неквалифицированной
                электронной подписи либо квалифицированной электронной подписи или иных их видов, и
                в формате PDF направить его Оператору на адрес электронной почты:
                {baseEmail}.
                <br />
                <br />- путем перехода по Гиперссылке{' '}
                <a href="/mailing-policy" target="_blank">
                    https://{baseUrl}/mailing-policy
                </a>
                , расположенной на сайте Оператора в телекоммуникационной сети «Интернет» по адресу:
                {baseUrl}, на текст Согласия и далее осуществить действия: по загрузке на свой
                персональный компьютер текста Согласия на получение рекламно-информационных рассылок
                (сообщений); распечатать тест Согласия; заполнить сведения о себе (ФИО, отчество
                (при наличии), номер основного документа удостоверяющего личность, сведения о дате
                выдаче, указанного документа и выдавшем его органе, адрес и дата рождения субъекта
                персональных данных); подписать собственноручно Согласие; сделать скан-копию и в
                формате PDF направить его Оператору на адрес электронной почты:
                {baseEmail}
            </p>

            <p>
                8. Пользователь гарантирует, что является совершеннолетним лицом, указанные им на
                Сайте email, номер телефона принадлежат ему лично, подтверждает правильность
                указанных им данных и соглашается с тем, что самостоятельно несет риск любых
                негативных последствий при указании недостоверных (неточных) сведений.
            </p>

            <p>
                9. Согласие может быть отозвано субъектом персональных данных или его представителем
                путем направления письменного заявления Оператору или его представителю по адресу
                местонахождения:115093, Россия, г.Москва, ул. Павловская д.6, а также путем
                направления соответствующего запроса на электронную почту {baseEmail}.
            </p>

            <p>
                10. Согласие действует в течение 5 (пяти лет) с даты его предоставления Оператору
                (партнерам Оператора) и до даты направления субъектом персональных данных
                уведомления об отказе от получения рекламно-информационных сообщений.
                <br />
                <br />- информирование меня по вопросам заключения и исполнения договора на оказание
                финансовых услуг, в целях надлежащего исполнения его условий, включая, но не
                ограничиваясь, информирование посредством направления ЅМЅ-сообщений и
                РUЅН-уведомлений (коротких текстовых уведомлений, передаваемых на мобильное
                персональное устройство с использованием технологии Google Cloud Messaging для
                операционной системы Android OS или технологии Apple Push Notification Service для
                операционной системы iOS), а также посредством отправки сообщения (в т.ч.
                голосового) на указанный мною номер мобильного телефона, в том числе с
                использованием технологии IVR, а также сообщения по электронной почте;
            </p>

            <p>
                Я даю согласие на получение от Оператора информации, в том числе рекламного
                характера (рекламу) путем совершения телефонных звонков, направления коротких
                текстовых сообщений (в том числе смс и push-уведомлений); электронных сообщений с
                использованием информационно-коммуникационных сервисов и (или) программ обмена
                мгновенными электронными сообщениями (мессенджеров)); в социальных сетях; по адресу
                электронной почты; номеру мобильного телефона, предоставленным мной.
                <br />
                <br />
            </p>

            <p>
                Настоящим я уведомлен (-а) о том, что могу отказаться от получения информации от
                Оператора, в т.ч. на электронную почту и смс-сообщений перейдя по ссылке в нижней
                части любого сообщения, полученного от Оператора.
            </p>
        </div>
    );
};
