import { FC } from 'react';

import './style.css';

export const Badge: FC<{ badgeContent: string }> = ({ badgeContent }) => {
    return (
        <div className="pb1OL0XL6">
            <div className="sb1OL0XL6">
                <div className="ab2K5T8CO">
                    <span tabIndex={-1} className="kb2XNslra mb2XNslra wb2XNslra">
                        <span className="Lb2XNslra" tabIndex={-1} style={{ outline: 'none' }}>
                            <span className="Kb2XNslra" style={{ paddingRight: 8, paddingLeft: 8 }}>
                                <span className="ab2XNslra">
                                    <span className="abfJWTd2g">{badgeContent}</span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );
};
